import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { ClientEventType, MeetingActionItem, SummaryJson } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../..';
import { HeaderThree, lightTheme } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SectionComponentProps, SummaryTextField } from '../shared';

const delimiter = '::-::';

const filter = createFilterOptions<string>();

export function ActionItemSectionEditor({
    summaryJSON,
    setSummaryJSON,
    usernameEmailMapping,
}: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Action Items</HeaderThree>
            </Row>

            {summaryJSON.actionItems?.map((item, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Action Item',
                            });
                            const updatedActionItems = [...(summaryJSON.actionItems ?? [])];
                            updatedActionItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                actionItems: updatedActionItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                placeholder="Action item title"
                                value={item.title}
                                onChange={(e) => {
                                    const updatedActionItems = [...(summaryJSON.actionItems ?? [])];
                                    updatedActionItems[i] = {
                                        ...updatedActionItems[i],
                                        title: e.target.value,
                                    };
                                    const updatedJson = {
                                        ...summaryJSON,
                                        actionItems: updatedActionItems,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                            />
                            <Column
                                style={{
                                    width: 'calc(100% - 20px)',
                                    marginLeft: '20px',
                                }}
                            >
                                <Autocomplete
                                    placeholder="Assignee"
                                    multiple
                                    size="small"
                                    value={item.users.map(
                                        (user, i) => `${user.username}${delimiter}${i}${delimiter}${user.email ?? ''}`
                                    )}
                                    freeSolo={true}
                                    selectOnFocus={true}
                                    id="action-item-owner-selection"
                                    options={summaryJSON.attendees}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        if (params.inputValue !== '') {
                                            filtered.push(`Add "${params.inputValue}"`);
                                        }

                                        return filtered;
                                    }}
                                    getOptionLabel={(username) => username.split(delimiter)[0]}
                                    onChange={(event, owners) => {
                                        const updatedActionItems = [...(summaryJSON.actionItems ?? [])];

                                        const partsList = owners.map((owner) => owner.split(delimiter));
                                        const users = partsList.map((parts) => ({
                                            // remove the instructional text if added
                                            username: parts[0].replaceAll('Add "', '').replaceAll('"', ''),
                                            email: parts[2] ?? usernameEmailMapping?.[parts[0]]?.email ?? '',
                                        }));

                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Action Item Owner Selection',
                                            OwnerDiff: users.length - updatedActionItems[i].users.length,
                                        });

                                        updatedActionItems[i] = {
                                            ...updatedActionItems[i],
                                            users,
                                        };
                                        const updatedJson = {
                                            ...summaryJSON,
                                            actionItems: updatedActionItems,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                    ChipProps={{
                                        style: { backgroundColor: lightTheme.neutrals.grayLight },
                                    }}
                                    fullWidth={false}
                                    getOptionDisabled={(option) =>
                                        !!summaryJSON.actionItems?.[i].users.some((user) => user.username === option)
                                    }
                                    style={{
                                        borderRadius: '6',
                                        width: '97.5%',
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    borderRadius: '20px',
                                                    padding: '4px 20px',
                                                    borderColor: `${lightTheme.primary.green} !important`,
                                                },
                                            }}
                                            placeholder="Add an assignee"
                                            variant="outlined"
                                        />
                                    )}
                                />

                                <SummaryTextField
                                    value={item.context}
                                    placeholder="Action item context"
                                    onChange={(e) => {
                                        const updatedActionItems = [...(summaryJSON.actionItems ?? [])];
                                        updatedActionItems[i] = {
                                            ...updatedActionItems[i],
                                            context: e.target.value,
                                        };
                                        const updatedJson = {
                                            ...summaryJSON,
                                            actionItems: updatedActionItems,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                />
                            </Column>
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Action Item',
                    });
                    const blankActionItem: MeetingActionItem = {
                        context: '',
                        title: '',
                        users: [],
                    };
                    const updatedActionItems = [...(summaryJSON.actionItems ?? []), blankActionItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        actionItems: updatedActionItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add an Action Item"
            />
        </>
    );
}
