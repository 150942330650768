import {
    ApolloMappedPerson,
    DemoVersion,
    ExperimentKey,
    ISOString,
    TicketIntegration,
    TicketIntegrationWithSubProject,
    TicketProject,
} from '.';
import { SlackUserSettings } from '..';
import { SpinachIntegration } from './Integration';
import { OutputPlatform, UUID, UserSeriesMetadata } from './Series';
import { ZoomParticipantRole } from './Zoom';
import { validateEnumUniqueness } from './validateEnumUniqueness';

export type KnowlegeBaseIntegrationSettings = {
    summaryParentPageId: string;
    integrationType: Extract<
        SpinachIntegration,
        SpinachIntegration.GoogleDrive | SpinachIntegration.Notion | SpinachIntegration.Confluence
    >;
};

export type GoogleDriveSeriesSettings = KnowlegeBaseIntegrationSettings & {
    integrationType: SpinachIntegration.GoogleDrive;
};

export type NotionSeriesSettings = KnowlegeBaseIntegrationSettings & {
    summaryParentPageName: string;
    integrationType: SpinachIntegration.Notion;
};

export type ConfluenceSeriesSettings = Omit<KnowlegeBaseIntegrationSettings, 'summaryParentPageId'> & {
    summaryParentPageId: string;
    pageOwnerName: string;
    pageOwnerAccountId: string;

    integrationType: SpinachIntegration.Confluence;
};

export type IntegrationSettings = {
    [SpinachIntegration.Confluence]?: ConfluenceSeriesSettings;
    [SpinachIntegration.Notion]?: NotionSeriesSettings;
    [SpinachIntegration.GoogleDrive]?: GoogleDriveSeriesSettings;
};

export enum IntegrationCode {
    Zoom = 'zoom',
    GoogleMeet = 'google-meet',
    MicrosoftTeams = 'microsoft-teams',
    Slack = 'slack',
    GoogleChat = 'google-chat',
    Discord = 'discord',
    WhatsApp = 'whats-app',
    Telegram = 'telegram',
    JiraSoftware = 'jira-software',
    Asana = 'asana',
    Trello = 'trello',
    Monday = 'monday-com',
    ClickUp = 'clickup',
    Github = 'github',
    Gitlab = 'gitlab',
    Linear = 'linear',
    Notion = 'notion',
    Other = 'other',
}

export const IntegrationLabelMap = {
    [IntegrationCode.GoogleMeet]: 'Google Meet',
    [IntegrationCode.MicrosoftTeams]: 'Microsoft Teams',
    [IntegrationCode.Zoom]: 'Zoom',
    [IntegrationCode.Slack]: 'Slack',
    [IntegrationCode.GoogleChat]: 'Google Chat',
    [IntegrationCode.Discord]: 'Discord',
    [IntegrationCode.WhatsApp]: 'Whatsapp',
    [IntegrationCode.Telegram]: 'Telegram',
    [IntegrationCode.JiraSoftware]: 'Jira Software',
    [IntegrationCode.Asana]: 'Asana',
    [IntegrationCode.Trello]: 'Trello',
    [IntegrationCode.Monday]: 'Monday.com',
    [IntegrationCode.ClickUp]: 'Clickup',
    [IntegrationCode.Github]: 'Github',
    [IntegrationCode.Gitlab]: 'Gitlab',
    [IntegrationCode.Linear]: 'Linear',
    [IntegrationCode.Notion]: 'Notion',
    [IntegrationCode.Other]: 'Other',
};

export enum HowDidYouHearLabel {
    LinkedIn = 'LinkedIn',
    GoogleSearch = 'Google Search',
    Advertisement = 'Advertisement',
    ProductHunt = 'Product Hunt',
    YCombinator = 'Y Combinator',
    Superhuman = 'Superhuman Newsletter',
    Zoom = 'Zoom Marketplace',
    FriendCoworker = 'A friend or co-worker',
    Other = 'Other',
}

export enum HowDidYouHearCode {
    LinkedIn = 'linkedin',
    GoogleSearch = 'google-search',
    Advertisement = 'advertisement',
    ProductHunt = 'producthunt',
    YCombinator = 'y-combinator',
    Superhuman = 'superhuman',
    Zoom = 'zoom-marketplace',
    FriendCoworker = 'friend-or-coworker',
    Other = 'other',
}

export enum EmailVerifyReason {
    BlockedEmail = 'BlockedEmail',
    CodeFailedToSend = 'CodeFailedToSend',
}

export enum EmailReferralResponseCode {
    Success = 'Success',
    Failed = 'Failed',
}

export const HowDidYouHearMap = {
    LinkedIn: {
        code: HowDidYouHearCode.LinkedIn,
        label: HowDidYouHearLabel.LinkedIn,
    },
    Advertisement: {
        code: HowDidYouHearCode.Advertisement,
        label: HowDidYouHearLabel.Advertisement,
    },
    GoogleSearch: {
        code: HowDidYouHearCode.GoogleSearch,
        label: HowDidYouHearLabel.GoogleSearch,
    },
    Superhuman: {
        code: HowDidYouHearCode.Superhuman,
        label: HowDidYouHearLabel.Superhuman,
    },
    ProductHunt: {
        code: HowDidYouHearCode.ProductHunt,
        label: HowDidYouHearLabel.ProductHunt,
    },
    YCombinator: {
        code: HowDidYouHearCode.YCombinator,
        label: HowDidYouHearLabel.YCombinator,
    },
    Zoom: {
        code: HowDidYouHearCode.Zoom,
        label: HowDidYouHearLabel.Zoom,
    },
    FriendCoworker: {
        code: HowDidYouHearCode.FriendCoworker,
        label: HowDidYouHearLabel.FriendCoworker,
    },
    Other: {
        code: HowDidYouHearCode.Other,
        label: HowDidYouHearLabel.Other,
    },
};

export type VerifyEmailRequest = {
    email: string;
};

export type VerifyEmailCodeRequest = VerifyEmailRequest & {
    code: string;
    experimentCode: string | null;
    demoVersion?: DemoVersion;
    deepLinkedSeriesId: string | null;
    referrer?: string;
};

export type EmailVerificationResponse = {
    success: boolean;
    reason?: EmailVerifyReason;
};

export type UserCreatable = {
    isNewUser?: boolean;
};

export type UserResponse = {
    user: IClientUser | null;
    isSsoRequired?: boolean;
    microsoftFlow?: boolean;
    errorCode?: string;
};

export type GoogleVerificationResponse = UserCreatable & UserResponse;
export type MicrosoftVerificationResponse = UserCreatable & UserResponse;
export type MicrosoftVerificationEmailCodeResponse = EmailVerificationResponse & {
    email?: string;
    isSsoRequired?: boolean;
    errorCode?: string;
};

export type EmailVerificationCodeResponse = MicrosoftVerificationEmailCodeResponse & UserCreatable & UserResponse;

export type SeriesDestinationSettings = {
    channelName?: string;
    channelId?: string;
    isPrivate: boolean;
};

export type UserDefaultDestinationSettings = SeriesDestinationSettings;

export type SummaryOutputSettings = {
    shouldReinstall?: boolean;
    platform: OutputPlatform.Slack;
    teamId?: string;
    teamType?: string;
    seriesId: string;
    destinationSettings: SeriesDestinationSettings[];
    defaultDestinationSettings?: SeriesDestinationSettings;
};

export type SeriesIntegrationSettings = IntegrationSettings & {
    /** @NOTE keeping for backward compatability. Can remove after ~1 week 8/14/23 */
    summaryOutputSettings?: SummaryOutputSettings[];
    slack?: SummaryOutputSettings;
};

export enum UserIntegrations {
    Jira = 'jira',
    GoogleCalendar = 'google-calendar',
    Slack = 'slack',
}

export type ClientUserSlackSettings = SlackUserSettings & {
    shouldReinstall: boolean;
};

export type ClientUserNotionSettings = {
    isAuthed: boolean;
    defaultParentPageId?: string;
    defaultParentPageName?: string;
    defaultDatabaseId?: string;
    defaultDatabaseName?: string;
    notionSummaryFolderId?: string;
    notionSummarySetupFailed?: boolean;
};

export type UserIntegrationSettings = Record<
    `${Exclude<
        SpinachIntegration,
        | TicketIntegration
        | SpinachIntegration.Slack
        | SpinachIntegration.GoogleDrive
        | SpinachIntegration.GoogleCalendar
        | SpinachIntegration.Zoom
    >}Settings`,
    { isAuthed: boolean } | undefined
> &
    Record<`${TicketIntegration}Settings`, { isAuthed: boolean; project?: TicketProject } | undefined> &
    Record<`${TicketIntegrationWithSubProject}Settings`, { subProject?: TicketProject } | undefined> & {
        notionSettings?: ClientUserNotionSettings;
        slackSettings?: ClientUserSlackSettings;
        confluenceSettings?: {
            isAuthed: boolean;
            confluenceSummarySetupFailed?: boolean;
            confluenceSummaryFolderId?: string;
            defaultSpaceKey?: string;
            /** @NOTE Why are these two optional? */
            confluenceAccountUrl?: string;
            confluenceAccountId?: string;
        };
        mondayDotComSettings?: {
            isAuthed: boolean;
            project?: TicketProject;
            subProject?: TicketProject;
        };
        jiraSettings?: {
            isAuthed: boolean;
            jiraAccountId: string;
            hasCreateScopes: boolean;
            jiraAccountUrl: string;
        };
        googleSettings?: {
            isAuthed: boolean;
            googleDriveSummarySetupFailed?: boolean;
            googleDriveSummaryFolderId?: string;
            isCalendarEnabled: boolean;
            isDriveEnabled: boolean;
        };
        microsoftSettings?: {
            isAuthed: boolean;
            isCalendarEnabled: boolean;
        };
    };

export enum PlatformSource {
    ChromeExtStore = 'chrome-ext-store',
}

/**
 * These keys are meant to be minimal in length, yet somewhat identifiable,
 * so that as users dismiss feature hints over time, they don't dominate the user metadata
 */
export enum FeatureDiscoveryKey {
    /** deprecated but kept for reference */
    // JiraIntegration = 'ji',

    AsanaIntegration = 'ai',
    SpinachReferral = 'sr',
    PayUp = 'pu',
    Generic = 'g-',
    OnboardingStepPromo = 'osp',
}

export enum CheckInFeatureDiscoveryKey {
    JiraIntegration = 'cji',
}

export type GenericFeatureKey = `${FeatureDiscoveryKey.Generic}${string}`;

export enum TooltipFeatureDiscoveryKey {
    JiraMagicInput = 'jmi',
    RegularUpdateSentiment = 'rus',
    RegularUpdateSentimentReasons = 'rsr',
}

export type DismissableHints =
    | FeatureDiscoveryKey
    | TooltipFeatureDiscoveryKey
    | GenericFeatureKey
    | CheckInFeatureDiscoveryKey;

validateEnumUniqueness([FeatureDiscoveryKey, TooltipFeatureDiscoveryKey, CheckInFeatureDiscoveryKey]);

// html element ID to attach to
export enum TooltipFeatureDiscoveryId {
    JiraMagicInputId = 'JiraMagicInputId',
}

/**
 * - `potential` - the user has the potential to be the first inviter
 *      (no other users from domain marked as first inviter) - but hasn't invited spinach yet
 * - `confirmed-yes` - the user has invited spinach to a meeting
 * - `confirmed-no` - confirmed that another user with this domain is the first inviter
 * - `unsupported` - the user is has a domain that existed before the feature was released and therefore is inconclusive
 */
export enum FirstDomainInviterStatus {
    Potential = 'potential',
    ConfirmedYes = 'confirmed-yes',
    ConfirmedNo = 'confirmed-no',
    Unsupported = 'unsupported',
}

export enum UserCreationSource {
    ScribeEmailInvite = 'scribe-email-invite',
    /** @New creation source for Recall V2 */
    LegacyEmailInvite = 'legacy-email-invite',
    GoogleCodeFlow = 'google-code-flow',
    GoogleSignIn = 'google-sign-in',
    GoogleSignInV2 = 'google-sign-in-v2',
    GoogleSignInRecallV2 = 'google-sign-in-recall-v2',
    MicrosoftSignInRecallV2 = 'microsoft-sign-in-recall-v2',
    MicrosoftSignIn = 'microsoft-sign-in',
    MicrosoftSignInFromCompanyWebsite = 'microsoft-sign-in-from-company-website',
    EmailVerificationFlow = 'email-verification-flow',
    ZoomInstall = 'zoom-install',
    AnonymousUserFlow = 'anonymous-user-flow',
    RecallMeetingEvent = 'recall-meeting-event',
    LegacyEmailSubscriptionInvite = 'legacy-email-subscription-invite',
    CognitoSSO = 'cognito-sso',
    SlackHomeViewing = 'slack-home-viewing',
    StripePaymentEvent = 'stripe-payment',
    StripeCheckoutReturn = 'stripe-checkout-return',
    DashboardCalendarInvite = 'dashboard-calendar-invite',
    SCIM = 'scim-create-user',
    MeetingShare = 'meeting-share',
    RecallCalendarSync = 'recall-calendar-sync',
}

export const LOGIN_CREATION_SOURCES: UserCreationSource[] = [
    UserCreationSource.GoogleCodeFlow,
    UserCreationSource.GoogleSignIn,
    UserCreationSource.GoogleSignInV2,
    UserCreationSource.GoogleSignInRecallV2,
    UserCreationSource.MicrosoftSignInRecallV2,
    UserCreationSource.MicrosoftSignIn,
    UserCreationSource.MicrosoftSignInFromCompanyWebsite,
    UserCreationSource.EmailVerificationFlow,
    UserCreationSource.ZoomInstall,
];

type AffiliateTracking = {
    celloAffiliateCode?: string;
};

export enum AddScribeToAllMeetingKind {
    All = 'all',
    AllMy = 'all-my',
    Internal = 'internal',
    External = 'external',
    MatchedByTitle = 'matched-by-title',
    RemoveFromAllMeetings = 'remove-from-all-meetings',
}
export type AddScribeToMeetingsTitlesToMatch = {
    id: string;
    text: string;
};

type AddScribeToAllMeetingConfiguration = {
    kind: AddScribeToAllMeetingKind.All;
};

type AddScribeToAllMyMeetingConfiguration = {
    kind: AddScribeToAllMeetingKind.AllMy;
};

type AddScribeToInternalMeetingConfiguration = {
    kind: AddScribeToAllMeetingKind.Internal;
};

type AddScribeToExternallMeetingConfiguration = {
    kind: AddScribeToAllMeetingKind.External;
};

type AddScribeToMeetingsMatchedByTitleConfiguration = {
    kind: AddScribeToAllMeetingKind.MatchedByTitle;
    titlesToMatch: AddScribeToMeetingsTitlesToMatch[];
};

export type AutomaticAddScribeToMeetingConfiguration =
    | AddScribeToAllMeetingConfiguration
    | AddScribeToAllMyMeetingConfiguration
    | AddScribeToInternalMeetingConfiguration
    | AddScribeToExternallMeetingConfiguration
    | AddScribeToMeetingsMatchedByTitleConfiguration
    | { kind?: null };

export type UserMetadata = Partial<UserIntegrationTracking> &
    UTMTracking &
    AiMeetingCounters &
    AffiliateTracking & {
        isSelfServe?: boolean;
        isFirstUserInACompanyAssociation?: boolean;
        companyName?: string;
        hasCompletedFirstMeeting?: boolean;
        lastLoggedOn?: ISOString;
        isUsingRecallCalendarV2?: boolean;
        isOnboardedWithRecallCalendarV2?: boolean;
        recallCalendarId?: string | null;
        brandedImageId?: string | null;
        lastEditedOn?: ISOString;
        createdOn?: ISOString;
        preferredName?: string;
        /**
         * @NOTE Allowing for null so that we can manually overwrite this value in Mongo.
         * Otherwise, Mongo makes it a bit more difficult to overwrite existing values, since `undefined`
         * gets converted to "do nothing" in the update operation.
         * */
        automaticAddScribeConfiguration?: AutomaticAddScribeToMeetingConfiguration | {} | null;
        howDidYouHear?: string;
        howDidYouHearOther?: string;
        numberOfPeopleInCompany?: string;
        firstName?: string;
        lastName?: string;
        middleName?: string;
        integrationsVideo?: IntegrationCode;
        integrationsVideoOther?: string;
        integrationsMessaging?: IntegrationCode;
        integrationsMessagingOther?: string;
        integrationsProjectMgmt?: IntegrationCode[];
        integrationsProjectMgmtOther?: string;
        intercomHash?: string;
        numberOfTimesViewingAllOutAsyncTour?: number;
        isJoiningSeriesUponOnboarding?: boolean;
        isOnboarded?: boolean;
        isBot?: boolean;
        practiceRoundsComplete?: number;
        /** @deprecated */
        hasDismissedDemoBanner?: boolean;
        totalSessions?: number;
        timezoneRegion?: string;
        isAnonymousUser?: boolean;
        anonymousUserId?: string;
        platformSource?: PlatformSource;
        /**
         * this is meant to contain feature discovery keys that the user has dismissed
         * that were specifically relevant to them. These keys are meant to be short and minimal
         * to reduce size of user metadata.
         */
        dismissedHints?: DismissableHints[];
        experimentCodes?: ExperimentKey[];
        signupBeforeDemo?: boolean;

        demoVersion?: DemoVersion;
        /** @deprecated */
        teamKind?: string;
        /** @deprecated */
        teamKindOther?: string;
        /** @deprecated */
        roleOnTeam?: string;
        /** @deprecated */
        roleOnTeamOther?: string;
        department?: string;
        otherDepartment?: string;

        hasLoggedIn?: boolean;
        rootDomain?: string;

        usedGoogleCodeFlow?: boolean;

        firstInviterForDomainStatus?: FirstDomainInviterStatus;

        hasInvitedSpinachToMeeting?: boolean;
        hasReadAsummary?: boolean;
        hasAddedFacilitation?: boolean;

        creationSource?: UserCreationSource;

        apolloId?: string;

        isEmailingIcpOnly?: boolean;

        lastInvoicePaidSuccessEventId?: string;
        lastCompletedCheckoutSessionId?: string;
        isPaidAi?: boolean;

        isEditingAiEmailsByDefault?: boolean;
        uploadVideoToGoogleDrive?: boolean;

        defaultMeetingLanguage?: string;
        defaultOutputLanguage?: string;

        accountReverseTrialStartDate?: Date;
        personalReverseTrialStartDate?: Date;

        shouldHideMeetingVideos?: boolean;
        shouldHideMeetingTranscripts?: boolean;

        accountLevelRecallZoomAuthCredentialId?: boolean;

        // this field is not being backfilled - it's used to ensure a user has received at least one summary by the time its used
        atLeastReceivedAiSummariesCount?: number;

        // SCIM
        scimUserId?: string;
        isScimActive?: boolean | null;
        /////

        userCreatedViaCalanderBasedSignIn?: boolean;
        // applies to last 30 days for non-paying users
        accountUsageForCycleInSeconds?: number;
        // applies to last 30 days for non-paying users
        personalUsageForCycleInSeconds?: number;
        teamUsageForCycleInSeconds?: number;

        // applies to starts for auto-capping based on their account's usage
        usageHoursForCurrentLimitedCycle?: number;
        currentHoursLastSetIsoDate?: string;
        lastLimitedCycleStartIsoDate?: string;

        mostRecentGtmId?: string;
        spinachReferrer?: string;

        lastHostedAiIso?: string;

        isMicrosoftOtpVerified?: boolean;

        isAccountOnboardedWithAgent?: boolean;
        hasBeenPreppedForSpinachToJoin?: boolean;
        teamSubscriptionPayerId?: string;
    };

export type AiMeetingCounterKey = `totalAi${string}SessionCount`;

export type AiMeetingCounters = {
    [x in AiMeetingCounterKey]?: number;
};

export type UserIntegrationTracking = {
    isIntegratedWithSlack: boolean;
    isIntegratedWithJira: boolean;
    isIntegratedWithAsana: boolean;
    isIntegratedWithGoogleCalendar: boolean;
    isIntegratedWithGoogleDrive: boolean;
    isIntegratedWithTrello: boolean;
    isIntegratedWithClickUp: boolean;
    isIntegratedWithNotion: boolean;
    isIntegratedWithLinear: boolean;
    isIntegratedWithMicrosoftCalendar: boolean;
    isIntegratedWithMondayDotCom: boolean;
};

export interface User {
    _id: UUID;
    email?: string;
    googleId?: string;
    microsoftId?: string;
    preferredName?: string;
    zoomUserId?: string;
    createdOn: ISOString;
    lastLoggedOn?: ISOString;
    lastEditedOn?: ISOString;
    recallZoomAuthCredentialId?: string;
    refreshToken?: string;
    schemaVersion?: number;
    seriesMetadataList?: UserSeriesMetadata[];
    metadata: UserMetadata;
    createdAt?: Date;
    updatedAt?: Date;
    stripeCustomerId?: string;
}

export enum UTMKey {
    UTMId = 'utm_id',
    UTMSource = 'utm_source',
    UTMMedium = 'utm_medium',
    UTMCampaign = 'utm_campaign',
    UTMTerm = 'utm_term',
    UTMContent = 'utm_content',
}

export type UTMTracking = {
    [UTMKey.UTMId]?: string;
    [UTMKey.UTMSource]?: string;
    [UTMKey.UTMMedium]?: string;
    [UTMKey.UTMCampaign]?: string;
    [UTMKey.UTMTerm]?: string;
    [UTMKey.UTMContent]?: string;
};

export interface IClientUser extends Omit<User, 'refreshToken'> {
    email: string;
    preferredName: string;
    integrationSettings?: UserIntegrationSettings;
    intercomHash?: string;
    featureToggles?: Record<FeatureToggle, FeatureFlagValue>;
    apolloData?: ApolloMappedPerson;
    hasProFeatures?: boolean;
}

export enum SendCalendarSuggestionsCohort {
    Slack = 'slack',
    Email = 'email',
    All = 'all',
}

export type BasicZoomParticipant = BaseUser & {
    meetingParticipantId: string;
    // Role is not defined InMainClient
    role?: ZoomParticipantRole;
};

export type BaseUser = {
    preferredName: string;
};

export enum FeatureToggle {
    Jira1 = 'jira',
    // TODO replace Avatar with Agent
    Avatar = 'avatar',
    Agent = 'avatar',
    JiraBoard = 'jira-board',
    TeamTopics2 = 'team-topics-2',
    SentimentCopy = 'sentiment-copy',
    MoodCheck = 'vibe-check',
    RecentCheckIns = 'recent-check-ins',
    AnonymousUserSupport = 'anonymous-user-support',
    LiveSummary = 'live-summary',
    NumberOfTimesUserCanViewAoATour = 'number-of-times-user-can-view-aoa-tour',
    AllowedInternalEmails = 'allowed-internal-emails',
    SSOBeta = 'sso-beta',
    SSODomains = 'sso-domains',
    SSOCheckOnGoogleSignIn = 'sso-check-on-google-sign-in',
    SSOCheckOnMicrosoftSignIn = 'sso-check-on-microsoft-sign-in',
    BypassSSOLoginDeactivation = 'bypass-sso-login-deactivation',
    ToggleRoundtable = 'disable-roundtable',
    EditUserName = 'edit-user-name',
    GoogleCalendar = 'google-calendar',
    DiscussionReactions = 'discussion-reactions',
    UserGoals = 'user-goals',
    Asana = 'asana-mvp',
    EmailSummaries = 'email-summaries',
    JiraIntegrationCallout = 'jira-int-callout',
    SentimentOpacity = 'sentiment-opacity',
    JiraMagicInputCallout = 'jira-magic-input-callout',
    Meditation = 'meditation-mvp',
    AsanaIntegrationCallout = 'asana-int-callout',
    ReferralCallout = 'referral-callout',
    PayupCalloutURL = 'pay-up-url',
    GeneratedSummaries = 'generated-summaries',
    GenericCalloutProps = 'generic-callout-props',
    StopStateMachineMonitoring = 'stop-state-machine-monitoring',
    SignupBeforeDemo = 'signup-before-demo',
    RegularItemSentiment = 'regular-item-sentiment',
    LiveItemSentimentDetails = 'live-item-sentiment-details',
    FreeTierLimited = 'free-tier',
    JiraSentimentInFooter = 'jira-sentiment-in-footer',
    IssueBased = 'continuity-1',
    CustomSlackReminders = 'custom-slack-reminders',
    ProgressNavigation = 'progress-navigation',
    JiraSearchAll = 'jira-search-all',
    IssueResolution = 'issue-resolution',
    DemoVersion = 'demo-version',
    InviteViaSlack = 'invite-via-slack',

    AISeriesAsanaIntegration = 'ai-series-asana-integration',
    AISeriesJiraIntegration = 'ai-series-jira-integration',
    AISummaryJiraIntegration = 'ai-summary-jira-integration',
    AISlackActionItems = 'ai-slack-action-items',
    AISlackDMPerPerson = 'ai-slack-dm-per-person',
    AISlackIssueActionItems = 'ai-slack-issue-action-items',
    AIShouldSendSlackActionsToAll = 'ai-should-send-slack-actions-to-all',

    ScribeMeetingType = 'scribe-meeting-type',
    ScribePerPersonStandupPromptTemplate = 'open-ai-transcript-summary-prompt',
    ScribeFlowBasedStandupPromptTemplate = 'open-ai-minimal-transcript-flow-prompt',
    ScribeRetroPromptTemplate = 'scribe-retro-prompt-template',
    ScribeWeeklyPromptTemplate = 'scribe-weekly-prompt-template',
    ScribeSprintPlanningPromptTemplate = 'scribe-sprint-planning-prompt-template',
    ScribeBacklogGroomingPromptTemplate = 'scribe-backlog-grooming-prompt-template',
    ScribeAllHandsPromptTemplate = 'scribe-all-hands-prompt-template',
    ScribeResearchSessionPromptTemplate = 'scribe-research-session-prompt-template',
    ScribeOneOnOnePromptTemplate = 'scribe-one-on-one-prompt-template',
    ScribeGenericPromptTemplate = 'scribe-generic-prompt-template',
    ScribeSprintPlanningWithTickets = 'scribe-sprint-planning-with-tickets',
    ScribeGenericWithTickets = 'scribe-generic-with-tickets',
    SlackDomainToTeamMapping = 'slack-domain-to-team-id-mapping',

    UseAiBrandingInMeeting = 'use-ai-branding-in-meeting',

    OpenAiModel = 'open-ai-model',
    ScribeMeetingDeterminationPromptTemplate = 'scribe-meeting-determination-prompt-template',
    ScribeUseGptMeetingDetermination = 'use-gpt-meeting-determination',
    EmailOrganizerOnly = 'email-organizer-only',
    SendSlackSummaryInDmAsDefault = 'slack-summary-in-dm-as-default',
    SlackHuddleUsersToNotDm = 'slack-huddle-users-to-not-dm',
    SlackHuddleInAppConnection = 'slack-huddle-in-app-connection',
    SlackHuddleAskToJoinMessage = 'slack-huddle-ask-to-join-message',
    ScribeEmbeddingOptions = 'scribe-embedding-options',
    ScribeEmbeddingTopK = 'scribe-embedding-topk',
    AiGoogleCalendar = 'ai-google-calendar',
    FavorEmbeddingsForActionItemsOutput = 'favor-embeddings-for-action-items',
    LinearIntegration = 'linear-integration',
    ClickUpIntegration = 'clickup-integration',
    ScribeOpenAiTemperature = 'scribe-open-ai-temperature',
    UseGpt4oForMegaprompt = 'use-gpt4o-for-megaprompt',
    ScribeOpenAiTemperatureFullTranscript = 'scribe-open-ai-temperature-full-transcript',
    ScribeApiRetryCount = 'scribe-api-retry-count',
    TrelloIntegration = 'trello-integration',
    ScribeSummaryActionItemsEmbedding = 'scribe-action-items-embedding',
    RootDomainManagement = 'root-domain-management',
    ConfluenceIntegration = 'confluence-integration',
    SkipConfluencePaidCheck = 'skip-confluence-paid-check',
    NotionIntegration = 'notion-integration',
    GoogleDriveIntegration = 'google-drive-integration',
    ScribeEmail = 'scribe-email',
    SlackSummaryRatingEnabled = 'slack-summary-rating-enabled',
    UseAssemblyAiLanguageDetection = 'use-assembly-ai-language-detection',
    RetryRecallTranscriptionOnError = 'retry-recall-transcription-on-error',
    TicketPaginationWaitTime = 'ticket-pagination-wait-time',
    LanguageSelectionInDashboard = 'language-selection-in-dashboard',
    OutputLanguageSelection = 'output-language-selection',
    OutputLanguagePerMeetingSelection = 'output-language-per-meeting-selection',

    // manually managed pro accounts pre-stripe
    ProAccount = 'pro-account',
    // we need to differentiate between manually managed trials and manually managed pro accounts (see above)
    ProTrialAccounts = 'pro-trial-accounts',
    // for now we have a handful of enterprise, and we'll track manually
    EnterpriseAccounts = 'enterprise-accounts',
    ManuallyManagedBillingDate = 'manually-managed-billing-date',
    GlobalSearch = 'global-search',

    TemporaryLinkToAsyncVideo = 'temporary-link-to-async-video',
    EmailCtaForAsyncVideo = 'email-cta-for-async-video',
    PaidAccountUsageDetails = 'paid-account-usage-details',

    /** @NOTE - this is disabled only for testing purposes only  */
    UseExternalValidationForTranscripts = 'use-external-validation-for-transcripts',
    TranscriptsMVP = 'transcripts-mvp',
    UseCustomVocabularyAsAssemblyAiWordBoost = 'use-custom-vocabulary-as-assembly-ai-word-boost',
    UseCustomVocabularyAsAssemblyAiCustomSpelling = 'use-custom-vocabulary-as-assembly-ai-custom-spelling',
    RunIntentWithFullTranscript = 'run-intent-with-full-transcript',
    PromptWithFullTranscriptMaxCharLength = 'prompt-with-full-transcript-max-char-length',
    GenerateMeetingAtomWithFullTranscript = 'generate-meeting-atom-with-full-transcript',
    RevisePreviousPromptResultForIntent = 'revise-previous-prompt-result-for-intent',
    PostChainDeduplication = 'post-chain-deduplication',
    UseAnthropicHaikuForIntent = 'use-anthropic-haiku-for-intent',
    AnthropicTopKForIntent = 'anthropic-top-k-for-intent',
    AnthropicTopPForIntent = 'anthropic-top-p-for-intent',
    JwtAccess = 'jwt-access',
    PrioritizeAzureOverOpenAI = 'use-azure-open-ai',
    SlackPublicFollowups = 'slack-public-followups',
    AzureStrategyProbability = 'azure-strategy-probability',
    UseAzureGpt4TurboStrategy = 'use-azure-gpt4-turbo-strategy',
    PrioritizeAzureEastEndpoints = 'prioritize-azure-east-endpoints',
    AzureGptContentFilterWords = 'azure-gpt-content-filter-words',
    RetroSummaryEmbedding = 'retro-summary-embedding',
    Azure32KOverOpenAi8kStrategyProbability = 'azure-32k-over-open-ai-8k-strategy-probability',
    SlackPublicIssueActions = 'slack-public-issue-actions',
    PaymentUrl = 'pro-payment-url',
    StripeUpgradeInApp = 'stripe-upgrade-in-app',
    ProAiPlanFeatureList = 'pro-ai-plan-feature-list',
    AiPlanFeatureListForFreeUsers = 'ai-plan-feature-list-for-free-users',
    AiPlanFeatureListForReverseTrialUsers = 'ai-plan-feature-list-for-reverse-trial-users',
    StripeCustomerPortal = 'stripe-customer-portal',
    WhatsSpinachEmailExperiment = 'whats-spinach-email-experiment',
    MicrosoftSignInEnabled = 'microsoft-sign-in-enabled',
    CalenderBasedSignedIn = 'calender-based-signed-in',
    RecallV2Auth = 'recall-v2-auth',
    VideoAgentIntroVideo = 'video-agent-intro-video',
    /**
     * @description users will be considered able to use Recall V2
     * if the RecallV2Auth flag is set to true, or if the RecallV2Onboarding flag
     * is set to true AND they are a new user, OR they onboarded (from the start) with Recall V2
     *
     * This will allow us to independently set the RecallV2Onboarding flag to 50%, 60%, 80%, etc.
     * and not impact existing users.
     * Doing this, X% of new users will onboard to our product with Recall V2; but, if existing users
     * log in (and are not already using Recall V2) or connect their calendar (and are not enabled with RecallV2Auth)
     * then they will not get upgraded to Recall V2
     * **/
    RecallV2Onboarding = 'recall-v2-onboarding',
    CalenderBasedSignedInErrorRedirectUrl = 'calender-based-signed-in-redirect-url',
    CalendarSuggestions = 'calendar-suggestion',
    NumberOfCalendarSuggestions = 'number-of-calendar-suggestions',
    RealtimePaidAccessEnabled = 'realtime-paid-access-enabled',
    SlackDMFollowUpsEnabled = 'ai-slack-action-items-with-followup',
    SlackDMIssueActions = 'slack-private-issue-actions',
    ForceEditFlow = 'edit-summary-mvp',
    EnhancedDiarizationEnabled = 'enhanced-diarization-enabled',
    CustomUUID = 'custom-uuid',
    NonOrganizerSpinachRemove = 'non-organizer-spinach-remove',
    SummariesInDashboard = 'summaries-in-dashboard',
    ChunkBasedPromptsOverlapInWords = 'chunk-based-prompts-overlap-in-words',
    ChunkBasedPromptsChunkSizeInWords = 'chunk-based-prompts-chunk-size-in-words',
    ConsolidatedGoogleCalanderScope = 'consolidated-google-calendar-scope',
    ChunkBasedPromptsTemperature = 'chunk-based-prompts-temperature',
    ChunkBasedPromptsOverlapInWordsRealTime = 'chunk-based-prompts-overlap-in-words-realtime',
    ChunkBasedPromptsChunkSizeInWordsRealTime = 'chunk-based-prompts-chunk-size-in-words-realtime',
    ChunkBasedPromptsTemperatureRealTime = 'chunk-based-prompts-temperature-realtime',
    ChunkBasedBlockers = 'chunk-based-blockers',
    SummaryGenerationAbTestFromUrl = 'summary-generation-ab-test-form-url',
    SyncCalendarEventsWithScribeResponseStatus = 'sync-calendar-events-with-scribe-response-status',
    SendIndividualEmails = 'send-individual-emails',
    ParticipantOverviewEmail = 'participant-overview-email',
    AIMeetingDashboardCombo = 'ai-dashboard-redesign',
    PerPersonJiraTicketFetch = 'per-person-jira-ticket-fetch',
    PerPersonJiraTicketFetchMaxAttendees = 'per-person-jira-ticket-fetch-max-attendees',
    ReverseTrialAiEnabled = 'reverse-trial-ai-enabled',
    AccountViralityTrackingEnabled = 'account-virality-tracking',
    PersonalReverseTrialAiEnabled = 'personal-reverse-trial-ai-enabled',
    ReverseTrialLengthInDays = 'reverse-trial-length-in-days',
    ScribeTranscriptionSettings = 'scribe-transcription-settings',
    ChainedActionItemGrouping = 'chained-action-item-grouping',
    ChainedBlockerGrouping = 'chained-blocker-grouping',
    FullTranscriptPlansAndProgress = 'full-transcript-plans-and-progress',
    PostGenerationPerPersonTicketMatching = 'post-generation-per-person-ticket-matching',
    WeeklySummaryEnabled = 'weekly-summary-enabled',
    GenerateAsyncVideoWithSummary = 'generate-async-video-with-summary',
    ActionItemCreationEnabledTicketSources = 'action-item-creation-enabled-ticket-sources',
    IsActionItemTicketCreationEnabled = 'action-item-ticket-creation',
    PreviousMeetingsInsightsSection = 'previous-meetings-insights-section',
    WorkstreamsSection = 'workstreams-section',
    AskSpinachInMeetingChat = 'ask-spinach-in-meeting-chat',
    PreviousMeetingBriefMessageInChat = 'previous-meeting-brief-message-in-chat',
    PreviousMeetingBriefMessageInChatDelay = 'previous-meeting-brief-message-in-chat-delay',
    LimitAIQuality = 'limit-ai-quality',
    MinimumRequiredHistories = 'minimum-required-histories',

    PreMeetingFacilitationNotification = 'ai-facilitation-notification',
    HeySpinachSectionEnabled = 'hey-spinach-section-enabled',
    HeySpinachCreateTicketEnabled = 'hey-spinach-create-ticket-enabled',
    HeySpinachSectionNumberOfWordsAfter = 'hey-spinach-section-number-of-words-after',
    HeySpinachSectionNumberOfWordsBefore = 'hey-spinach-section-number-of-words-before',
    HeySpinachSectionTemperature = 'hey-spinach-section-temperature',
    HeySpinachThreshold = 'hey-spinach-threshold',
    RealtimeBlockersEnabled = 'realtime-blockers-enabled',

    ZoomIntegration = 'zoom-integration',
    CustomVocabularySettings = 'custom-vocabulary-settings',
    JobTimeoutEnabled = 'job-timeout-enabled',
    RejectOnJobTimeoutEnabled = 'reject-on-job-timeout-enabled',
    JobTimeoutMinutes = 'job-timeout-minutes',

    InMeetingMessageConnectZoom = 'in-meeting-message-connect-zoom',
    InMeetingMessageConnectZoomMessage = 'in-meeting-connect-zoom-message',

    /** An empty string will signify disabled */
    GenericZoomDirectMessage = 'generic-zoom-direct-message',

    UseInMeetingGenericMessage = 'use-in-meeting-generic-message',
    InMeetingGenericMessage = 'in-meeting-generic-message',
    InMeetingGenericMessageDelay = 'in-meeting-generic-message-delay',

    StripePlanIds = 'stripe-plan-ids',
    TaxIdCollectionInCheckout = 'tax-id-collection-in-checkout',
    ForceCompanyWideCheckout = 'force-company-wide-checkout',
    MeetingVideoPlayer = 'meeting-video-player',
    MeetingVideoDownload = 'meeting-video-download',
    MinimumTranscriptWordCount = 'minimum-transcript-word-count',
    MinimumTranscriptCharCount = 'minimum-transcript-char-count',

    DisableScribeProcessing = 'disable-scribe-processing',
    ShouldFetchSeriesFeatureFlags = 'should-fetch-series-feature-flags',
    ForceStandupAppOnboarding = 'force-legacy-onboarding',
    HideAiDashboard = 'hide-ai-dashboard',
    HideStandupAppDashboard = 'hide-standup-app-dashboard',
    NewStandupAppDashboard = 'new-standup-app-dashboard',
    FakeDeleteMeetingAfterAgendaCheckbox = 'fake-delete-meeting-after-agenda-checkbox',
    MissingZoomAuthBanner = 'missing-zoom-auth-banner',
    ScribeConcurrency = 'scribe-concurrency',
    CleanTimedOutJobsFromQueue = 'clean-timed-out-jobs-from-queue',

    PrioritizePaidJobs = 'prioritize-paid-jobs',
    CustomJobPriority = 'custom-job-priority',

    DisableKnowledgeBaseIntegrationForFreeUsers = 'disable-knowledge-base-integration-for-free-users',
    EditMeetingType = 'edit-meeting-type',
    CombineInMeetingAndAiMeeting = 'combine-in-meeting-and-ai-meeting',
    ShortenedOnboardingFlow = 'shortened-onboarding-flow',

    FreeTierDelayMinutes = 'free-tier-delay-minutes',
    CustomVocabularyCorrection = 'custom-vocabulary-correction',
    CustomVocabularyCorrectionShadow = 'custom-vocabulary-correction-shadow',
    IncludeUserResearchInterviewInMeetingTypeSelection = 'include-user-research-interview-in-meeting-type-selection',
    IncludeCustomerSuccessCheckInInMeetingTypeSelection = 'include-customer-success-check-in-in-meeting-type-selection',
    IncludeGeneralSalesMeetingInMeetingTypeSelection = 'include-general-sales-meeting-in-meeting-type-selection',
    AffiliateProgram = 'affiliate-program',

    CustomBotBranding = 'custom-bot-branding',
    AccountCustomBotBranding = 'account-custom-bot-branding',
    CustomVocabularySettingsPremiumOnly = 'custom-vocabulary-settings-premium-only',

    LinkHistoriesMaxDiffStartTimeMinutes = 'link-histories-max-diff-start-time-minutes',

    UseManualScribeVideoOutput = 'use-manual-scribe-video-background-image',
    AutomateApolloAccountBackfill = 'automate-apollo-account-backfill',

    ToggleMeetingVideo = 'toggle-meeting-video',
    ToggleMeetingTranscripts = 'toggle-meeting-transcripts',
    HideMeetingVideosOverride = 'hide-meeting-video-override',
    HideMeetingTranscriptsOverride = 'hide-meeting-transcripts-override',
    RedirectDomainToProvisioningCheck = 'redirect-domain-to-provisioning-check',
    SSOProvisioningInstructionInformation = 'sso-provisioning-instruction-information',
    SwapEmailSummaryForNotification = 'email-with-deep-link-to-summary',
    IndividualSummaryEmailTemplateV2 = 'individual-summary-email-template-v2',

    ProvisioningEmailSection = 'provisioning-email-section',
    ProvisioningEmailSectionCtaUrl = 'provisioning-email-section-cta-url',

    AddContextToTicketFromStandupUpdateEnabledTicketSources = 'add-context-to-ticket-from-standup-update-enabled-ticket-sources',
    AddContextToTicketFromStandupUpdate = 'add-context-to-ticket-from-standup-update',
    // used for testing
    ForceBillableUsers = 'force-billable-users',
    ForceBillableHours = 'force-billable-hours',
    AutoManagedSubscriptionPlanIds = 'auto-managed-subscription-plan-ids',
    FilterDashboardAccessBasedOnEmail = 'filter-dashboard-access-based-on-email',
    SlackChannelPageLimit = 'slack-channel-page-limit',
    SlackConversationMembersPageLimit = 'slack-conversation-members-page-limit',
    SupportLargeSlackChannelSet = 'support-large-slack-channel-set',

    ChurnIndicationOnJobComplete = 'churn-indication-on-job-complete',
    AnonymousIdFlowExperimentConfiguration = 'anonymous-id-flow-experiment-configuration',
    OnboardingDisableRoleAndTeamTypeQuestion = 'onboarding-disable-role-and-team-type-questions',
    OnboardingPrivacyPolicyCheckbox = 'onboarding-privacy-policy-checkbox',
    AskSpinachEnabledQueries = 'ask-spinach-enabled-queries',
    AskSpinachEnabledQueriesBaseline = 'ask-spinach-enabled-queries-baseline',
    AskSpinachRequestsPerHour = 'ask-spinach-requests-per-hour',

    AskSpinachSlack = 'ask-spinach-slack',
    MultiMeetingAskSpinach = 'multi-meeting-ask-spinach',
    multiMeetingAskSpinachNumberOfPastMeetings = 'multi-meeting-ask-spinach-number-of-past-meetings',

    DataRetentionExpirationInDays = 'data-retention-expiration-in-days',
    DataRetentionListener = 'data-retention-listener',

    // controls whether, upon call end, we compute most recent usage for host and account if applicable.
    // this is required by the two following usage flags. if computation is off, it does not make sense to
    // display visibility or syncing
    UsageComputation = 'usage-computation',
    // controls whether we display the usage in the front-end
    UsageVisibility = 'usage-visibility',
    DraftsSection = 'drafts-section',
    UsageDefaultCycleDays = 'usage-default-cycle-days',

    TalkToSalesURL = 'talk-to-sales-url',

    OnboardingExperiment = 'onboarding-experiment',
    NumberOfPeopleInCompanyQuestion = 'number-of-people-in-company-question',
    CalendarPermissionsWithMeetingsPlaceholder = 'calendar-permissions-with-meetings-placeholder',

    SupportEditAfterCustomizingSections = 'support-edit-after-customizing-sections',

    ConfluenceUserMentions = 'confluence-user-mentions',

    AdminMixpanelEmbedAccess = 'admin-mixpanel-embed-access',
    MixpanelAdminEmbedUrls = 'mixpanel-admin-embed-urls',
    AdminUserContentAccess = 'admin-user-content-access',

    ConfluenceSpaceSelection = 'confluence-space-selection',
    PasteAtlassianSiteSelection = 'paste-atlassian-site-selection',

    DelayedSignUpTracking = 'delayed-sign-up-tracking',
    InVideoMeetingPauseIsPaidFeatureMessageText = 'in-video-meeting-pause-is-paid-feature-message-text',
    InVideoMeetingPauseIsPaidFeatureMessage = 'in-video-meeting-pause-is-paid-feature-message',

    PaginateConfluenceSpaces = 'paginate-confluence-spaces',
    confluenceSpacePaginationTimeoutInMilliseconds = 'confluence-space-pagination-timeout-in-milliseconds',
    MicrosoftOtpTidOidSubCheck = 'microsoft-otp-tid-oid-sub-check',
    MicrosoftOtpVerifiedTenantIdCheck = 'microsoft-otp-verified-tenant-id-check',

    ViralityLandingPage = 'virality-landing-page',
    CombinedGenericSummaries = 'combined-generic-summaries',

    ScimAccess = 'scim-access',

    HourlyUsageLimitPerCycle = 'hourly-usage-limit-per-cycle',
    AutoCappingStarters = 'auto-capping-starters',
    AvatarWelcomeJoiningParticipant = 'avatar-welcome-joining-participant',
    DisableEmbeddingForSummaryGeneration = 'disable-embedding-for-summary-generation',

    AddToAllMeetings = 'add-to-all-meetings',
    SkipEmbeddingCreation = 'skip-embedding-creation',
    LimitedCycleStartDateOverride = 'limited-cycle-start-date-override',
    CatchAllLimitedCycleStartDate = 'catch-all-limited-cycle-start-date',

    UseLiveNotesV2 = 'use-live-notes-v2',
    BlockUnprovisionedUser = 'block-unprovisioned-user',
    AgentTopicObjective = 'agent-topic-objective',

    RealtimeAskSpinach = 'real-time-ask-spinach',
    UnityBasecFeature = 'unity-based-avatar',
    PartialCapping = 'partial-capping',

    NumberTopicsForPartialSummary = 'number-topics-for-partial-summary',

    ShareAIHistory = 'share-ai-history',
    CustomMeetingNotesHistoryLimit = 'custom-meeting-notes-history-limit',

    SelfServeUserDelete = 'self-serve-user-delete',
    SelfServeHostDeleteMeetingNotes = 'self-serve-host-delete-meeting-notes',

    PartiallyPayingAccount = 'partially-paying-account',

    EmailElementsBlockedFromContentAccess = 'email-elements-blocked-from-content-access',

    VideoAgentAgenda = 'video-agent-agenda',
    VideoAgentAgendaRecallV2 = 'video-agent-agenda-recall-v2',
    VideoAgentFeedbackCollection = 'video-agent-feedback-collection',
    VideoAgentAssetMap = 'video-agent-asset-map',
    VideoAgentConfig = 'video-agent-config',

    CelloReferralProgram = 'cello-referral-program',
    ScheduleOnboardingSessionUrl = 'schedule-onboarding-session-url',

    LogRecallV2DiffInformation = 'log-recall-v2-diff-information',
    CustomFeatureSeriesSettings = 'custom-feature-series-settings',
    FetchRecallCalendarEventUsingIdFromBotMetadata = 'fetch-recall-calendar-event-using-id-from-bot-metadata',

    MondayDotComUI = 'monday-dot-com-ui',

    RealtimeRecallV2BotDeduplication = 'realtime-recall-v2-bot-deduplication',
    RealtimeRecallV2BotDeduplicationDelayInSeconds = 'realtime-recall-v2-bot-deduplication-delay-in-seconds',

    AgentAudioUponChatCommands = 'agent-audio-upon-chat-commands',

    VideoAgentAskSpinachContextSeriesId = 'video-agent-ask-spinach-context-series-id',

    OnboardAccountForAgent = 'onboard-account-for-agent',
    BlockAgentForAgentOnboardedAccount = 'block-agent-for-agent-onboarded-account',
    UploadVideoToGoogleDrive = 'upload-video-to-google-drive',

    VideoAgentWelcomeMessage = 'video-agent-welcome-message',
    VideoAgentSelfServeSeriesToggle = 'video-agent-self-serve-series-toggle',
    VideoAgentDemoPreviousContext = 'video-agent-demo-previous-context-for-names',

    VoiceAsPrimaryAgentCommandKind = 'voice-as-primary-agent-command-kind',
    GroupEventSyncFor = 'group-event-sync-for',
    SkipAlreadyProcessedCalendarSyncEvents = 'skip-already-processed-calendar-sync-events',
    ProbabilityOfUsingQueuesForCalendarSyncWebhook = 'probability-of-using-queues-for-calendar-sync-webhook',
    NumberOfConcurrentConsumersForCalendarSyncWebhook = 'number-of-concurrent-consumers-for-calendar-sync-webhook',

    RecordSessionsOnFullStory = 'record-sessions-on-fullstory',
    MeetingInformationTooltips = 'meeting-information-tooltips',

    OnboardWithoutCalendarIntegration = 'onboard-without-calendar-integration',

    ClaimHost = 'claim-host',
    MeetingTypeClassification = 'meeting-type-classification',
    RunSilentMeetingTypeClassificationIntent = 'run-silent-meeting-type-classification-intent',

    RecurringMeetingSettingsNotice = 'recurring-meeting-settings-notice',
}

export type FeatureFlagSet<T extends string> = Record<T, FeatureFlagValue>;
export type FeatureFlagValue = number | boolean | string | Array<FeatureFlagValue> | Object;

export type UserIdentity = {
    userId: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    rootDomain: string;
    howDidYouHearSource?: string;
    userName: string;
    userEmail: string;
    seriesMetadataList?: UserSeriesMetadata[];
    metadata?: UserMetadata;
};

export enum FeatureIntent {
    PerPersonUpdates = 'per-person-updates',
    ActionItems = 'action-items',
    IssueActions = 'issue-actions',
    MeetingTypeDetermination = 'meeting-type-determination',
    NameEmailMapping = 'name-email-mapping',
    MeetingSummary = 'meeting-summary',
    TranscriptEmbedding = 'transcript-embedding',
    MeetingActionItems = 'meeting-action-items',
    MeetingActionItemsRevision = 'meeting-action-items-revision',
    MeetingActionItemsFullTranscript = 'meeting-action-items-full-transcript',
    MeetingBlockers = 'meeting-blockers',
    MeetingBlockersRevision = 'meeting-blockers-revision',
    MeetingKeyDecisions = 'meeting-key-decisions',
    MeetingCelebrations = 'meeting-celebrations',
    MeetingAgendaItems = 'meeting-agenda-items',
    ActionItemDeduplication = 'action-item-deduplication',
    SummaryJsonTranslation = 'summary-json-translation',
    ActionItemChunkResultConsolidation = 'action-item-chunk-result-consolidation',
    BlockerDeduplication = 'blocker-deduplication',
    MeetingWhatWentWell = 'meeting-what-went-well',
    MeetingWhatDidNotGoWell = 'meeting-what-did-not-go-well',
    MeetingWhatCanBeImproved = 'meeting-what-can-be-improved',
    MeetingTicketMatching = 'meeting-ticket-matching',
    MeetingHeySpinachSection = 'meeting-hey-spinach-section',
    MapReducePipelineTest = 'map-reduce-pipeline-test',
    PreviousMeetingInsights = 'previous-meeting-insights',
    WorkstreamsFromMeeting = 'workstreams-from-meeting',
    WorkstreamWeeklyOverview = 'workstream-weekly-overview',
    CustomVocabulary = 'custom-vocabulary',
    KeyResearchTakeaways = 'key-research-takeaways',
    ResearchInterviewQuotesBank = 'research-interview-quotes-bank',
    AskSpinach = 'ask-spinach',
    AsyncVideo = 'async-video',
    MeetingWorkstreams = 'meeting-workstreams',
    MeetingGeneralSalesCall = 'meeting-general-sales-call',
    MeetingCompanyAllHandsSummary = 'meeting-company-all-hands-summary',
    MeetingTypeClassificationWithTranscript = 'meeting-type-classification-with-transcript',
    MeetingCustomerSuccessCheckIn = 'meeting-customer-success-checkin',
}

export type SummaryFeatureIntents = Extract<
    FeatureIntent,
    | FeatureIntent.MeetingBlockers
    | FeatureIntent.MeetingKeyDecisions
    | FeatureIntent.MeetingActionItems
    | FeatureIntent.MeetingAgendaItems
    | FeatureIntent.MeetingWhatWentWell
    | FeatureIntent.MeetingWhatDidNotGoWell
    | FeatureIntent.MeetingWhatCanBeImproved
    | FeatureIntent.MeetingCelebrations
    | FeatureIntent.PerPersonUpdates
    | FeatureIntent.MeetingHeySpinachSection
    | FeatureIntent.KeyResearchTakeaways
    | FeatureIntent.ResearchInterviewQuotesBank
    | FeatureIntent.PreviousMeetingInsights
    | FeatureIntent.MeetingWorkstreams
    | FeatureIntent.MeetingGeneralSalesCall
    | FeatureIntent.MeetingCompanyAllHandsSummary
    | FeatureIntent.MeetingCustomerSuccessCheckIn
>;

export type CustomizableSummaryFeatureIntents = Exclude<
    SummaryFeatureIntents,
    | FeatureIntent.KeyResearchTakeaways
    | FeatureIntent.ResearchInterviewQuotesBank
    | FeatureIntent.MeetingHeySpinachSection
    | FeatureIntent.MeetingWorkstreams
    | FeatureIntent.MeetingGeneralSalesCall
    | FeatureIntent.MeetingCustomerSuccessCheckIn
    | FeatureIntent.MeetingCompanyAllHandsSummary
>;

export type NonCustomizableIntents = Exclude<SummaryFeatureIntents, CustomizableSummaryFeatureIntents>;

export function isCustomizableSummaryFeatureIntent(
    intent: SummaryFeatureIntents
): intent is CustomizableSummaryFeatureIntents {
    return !(intent in (Object.values(FeatureIntent) as NonCustomizableIntents[]));
}
