import { ClientEventType, MeetingCelebration, SummaryJson } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../..';
import { BodyRegularOnboard, HeaderThree } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SectionComponentProps, SummaryTextField } from '../shared';

export function CelebrationSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Celebrations</HeaderThree>
            </Row>

            {summaryJSON.celebrations?.map((celebration, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Celebration',
                            });
                            const updatedItems = [...(summaryJSON.celebrations ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                celebrations: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <BodyRegularOnboard>
                                <SummaryTextField
                                    InputProps={{ style: { fontWeight: 600 } }}
                                    value={celebration.user}
                                    onChange={(e) => {
                                        const updatedCelebrations = [...(summaryJSON.celebrations ?? [])];
                                        updatedCelebrations[i] = {
                                            ...updatedCelebrations[i],
                                            user: e.target.value,
                                        };
                                        const updatedJson: SummaryJson = {
                                            ...summaryJSON,
                                            celebrations: updatedCelebrations,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                    placeholder="Who are we celebrating?"
                                />
                                <SummaryTextField
                                    value={celebration.content}
                                    onChange={(e) => {
                                        const updatedCelebrations = [...(summaryJSON.celebrations ?? [])];
                                        updatedCelebrations[i] = {
                                            ...updatedCelebrations[i],
                                            content: e.target.value,
                                        };
                                        const updatedJson: SummaryJson = {
                                            ...summaryJSON,
                                            celebrations: updatedCelebrations,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                    placeholder="What are we celebrating"
                                />
                            </BodyRegularOnboard>
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Celebration',
                    });
                    const blankItem: MeetingCelebration = {
                        content: '',
                        user: '',
                    };
                    const updatedItems = [...(summaryJSON.celebrations ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        celebrations: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Celebration"
            />
        </>
    );
}
