import { MeetingGeneralSalesCallJSON } from '@spinach-shared/types';

import { SectionComponentProps } from '../shared';
import { GenericSectionEditor, MeetingGeneratedItemsSectionEditor, SectionConfiguration } from './GenericSectionEditor';

export function GeneralSalesCallSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const sections: SectionConfiguration<MeetingGeneralSalesCallJSON>[] = [
        { title: 'Call Summary', kind: 'text', key: 'callSummary' },
        { title: 'Interests', itemType: 'Interest', key: 'interests', kind: 'generated-items' },
        { title: 'Concerns', itemType: 'Concern', key: 'concerns', kind: 'generated-items' },
        { title: 'Outcome and Takeaway', kind: 'text', key: 'outcomeAndTakeaway' },
    ];

    if (!summaryJSON.generalSalesCallSummary) {
        return <></>;
    }

    const agendaItemsAsMeetingGeneratedItems = (summaryJSON.generalSalesCallSummary?.agendaItems ?? []).map((item) => ({
        title: item.content,
        context: item.context,
    }));

    return (
        <>
            <GenericSectionEditor<MeetingGeneralSalesCallJSON, 'generalSalesCallSummary'>
                summaryJSON={summaryJSON}
                setSummaryJSON={setSummaryJSON}
                sections={sections}
                sectionKey="generalSalesCallSummary"
            />
            <MeetingGeneratedItemsSectionEditor
                sectionTitle="Key Topics"
                itemTypeName="Key Topic"
                items={agendaItemsAsMeetingGeneratedItems ?? []}
                onUpdatedItems={(items) => {
                    setSummaryJSON({
                        ...summaryJSON,
                        generalSalesCallSummary: {
                            ...summaryJSON.generalSalesCallSummary!,
                            agendaItems: items.map((item) => ({
                                content: item.title || '',
                                context: item.context || '',
                            })),
                        },
                    });
                }}
            />
        </>
    );
}
