import { IconButton, StandardTextFieldProps, TextField, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { ReactNode, useState } from 'react';

import { MeetingGeneratedItem, SummaryJson, UserNameWithEmailConfidenceMap } from '@spinach-shared/types';
import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../hooks';
import { useGlobalAiDashboard } from '../../../../../hooks/useGlobalAiDashboard';
import { HeaderThree, lightTheme } from '../../../../../styles';
import { SetValue } from '../../../../../types';
import { withContentMasking } from '../../../../../utils';
import { Column, Row } from '../../../../common';
import SecondaryButton from '../../../../stand-up/SecondaryButton';

const useSummaryTextStyles = makeStyles(() => ({
    root: {
        border: `1px solid white`,
        overflow: 'hidden',
        borderRadius: 6,
        padding: '2px',
        width: '97%',
        '&:hover': {
            backgroundColor: '#fff',
            border: `1px solid ${lightTheme.primary.orangeLight}`,
        },
        // would love to figure out how to keep orange border on focus without hover
        // '&:focus': {
        //     border: `0.5px solid ${lightTheme.primary.orangeDark}`,
        // },
    },
}));

export type SectionComponentProps = {
    summaryJSON: SummaryJson;
    setSummaryJSON: SetValue<SummaryJson | null | undefined>;
    usernameEmailMapping: UserNameWithEmailConfidenceMap | undefined;
};

export function SummaryTextField(props: StandardTextFieldProps): JSX.Element {
    const { state, setState } = useGlobalAiDashboard();
    const classes = useSummaryTextStyles();
    const track = useExperienceTracking();

    return (
        <TextField
            size="small"
            classes={classes}
            {...withContentMasking(props.className)}
            multiline
            {...props}
            /** @NOTE InputProps needs to be after {...props} */
            onChange={(e) => {
                props.onChange?.(e);
                if (!state.hasEditedSummary) {
                    track(ClientEventType.AIDashboardActivity, {
                        Activity: 'Started Editing Summary',
                    });
                    setState({ ...state, hasEditedSummary: true });
                }
            }}
            inputProps={{
                maxLength: 5000,
            }}
            InputProps={{
                disableUnderline: true,
                style: { fontFamily: 'Gilroy', ...(props.InputProps?.style ?? {}) },
                ...(props.InputProps ?? {}),
            }}
        />
    );
}

export function RemoveItemButton({ onClick }: { onClick: () => void | (() => Promise<void>) }): JSX.Element {
    return (
        <div style={{ position: 'relative', width: '0px' }}>
            <div style={{ position: 'absolute', left: '-30px' }}>
                <IconButton size="small" onClick={onClick}>
                    <RemoveCircleIcon htmlColor={lightTheme.primary.orangeDark} />
                </IconButton>
            </div>
        </div>
    );
}

export function AddItemRow({
    buttonText,
    onClick,
}: {
    buttonText: ReactNode;
    onClick: () => void | (() => Promise<void>);
}): JSX.Element {
    return (
        <Row>
            <SecondaryButton
                title={
                    <>
                        <AddCircleIcon htmlColor={lightTheme.primary.greenLight} />
                        {buttonText}
                    </>
                }
                buttonStyles={{ textDecoration: 'none' }}
                color={lightTheme.primary.greenLight}
                labelStyles={{ fontSize: '18px', fontWeight: 700, display: 'flex', alignItems: 'center' }}
                onClick={onClick}
            />
        </Row>
    );
}

export function ItemRow({
    children,
    onRemoveClick,
    rowIndex,
}: {
    onRemoveClick: () => void;
    children?: ReactNode;
    rowIndex: number;
}): JSX.Element {
    const [highlightedItemIndex, setHighlightedItemIndex] = useState(-1);

    return (
        <Row
            style={{ marginBottom: '10px' }}
            onMouseEnter={() => setHighlightedItemIndex(rowIndex)}
            onMouseLeave={() => setHighlightedItemIndex(-1)}
        >
            {highlightedItemIndex === rowIndex ? <RemoveItemButton onClick={onRemoveClick} /> : null}
            {children}
        </Row>
    );
}
