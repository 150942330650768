import { Box } from '@material-ui/core';

export const AboutToMatchIndicator = ({
    aboutToMatch,
    wakeWordDetected,
    unknown,
}: {
    aboutToMatch: boolean;
    wakeWordDetected: boolean;
    unknown: boolean;
}) => (
    <>
        {aboutToMatch || wakeWordDetected || unknown ? (
            <Box
                style={{
                    bottom: '0px',
                    textAlign: 'center',
                    fontSize: '140px',
                    fontWeight: 600,
                    color: unknown ? '#FF7201' : wakeWordDetected ? '#17FE03' : 'white',
                    position: 'fixed',
                    zIndex: 150,
                    transform: 'translateX(-50%)',
                }}
            >
                ...
            </Box>
        ) : undefined}
    </>
);
