import { MEETING_TYPE_FEATURE_INTENT_MAP } from '@spinach-shared/constants';
import { ScribeMeetingType, SummaryFeatureIntents } from '@spinach-shared/types';

export function isMeetingTypeEnabledForEditing(meetingType: ScribeMeetingType | undefined): boolean {
    if (!meetingType) {
        return false;
    }

    const isMeetingTypeSupported =
        meetingType === ScribeMeetingType.Standup ||
        meetingType === ScribeMeetingType.Generic ||
        meetingType === ScribeMeetingType.CustomerSuccessCheckIn ||
        meetingType === ScribeMeetingType.GeneralSalesMeeting;

    return isMeetingTypeSupported;
}

export function haveSummarySectionsBeenEdited(
    meetingType: ScribeMeetingType | undefined,
    seriesEnabledSummarySections: SummaryFeatureIntents[] | undefined
) {
    if (!meetingType) {
        return false;
    }

    if (!seriesEnabledSummarySections) {
        return false;
    }

    return !MEETING_TYPE_FEATURE_INTENT_MAP[meetingType].every((section) =>
        seriesEnabledSummarySections.includes(section)
    );
}

export function getMeetingTypeByTitleKeyword(meetingTitle: string): ScribeMeetingType {
    const STANDUP_MATCHES = [
        'standup',
        'daily',
        'stand-up',
        'stand up',
        'dsu',
        'scrum',
        'huddle',
        'check-in',
        'checkin',
        'checkin',
        'check in',
    ];
    const RETRO_MATCHES = ['retro'];
    const BACKLOG_GROOMING_MATCHES = ['refine', 'groom', 'backlog'];
    const PLANNING_MATCHES = ['sprint', 'planning', 'kickoff'];

    let returnedMeetingType = ScribeMeetingType.Generic;

    const searchable = meetingTitle.toLowerCase();

    if (STANDUP_MATCHES.some((match) => searchable.includes(match))) {
        returnedMeetingType = ScribeMeetingType.Standup;
    } else if (RETRO_MATCHES.some((match) => searchable.includes(match))) {
        returnedMeetingType = ScribeMeetingType.Retro;
    } else if (BACKLOG_GROOMING_MATCHES.some((match) => searchable.includes(match))) {
        returnedMeetingType = ScribeMeetingType.BacklogGrooming;
    } else if (PLANNING_MATCHES.some((match) => searchable.includes(match))) {
        returnedMeetingType = ScribeMeetingType.SprintPlanning;
    }

    return returnedMeetingType;
}
