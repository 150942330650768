import * as Sentry from '@sentry/react';
import { atom } from 'recoil';
import { z } from 'zod';

import { VideoAgentSession } from '@spinach-shared/models';

export type VideoAgentAssetMap = {
    listeningSrc: string;
    askSpinachAnswering: string;
    askSpinachThinking: string;
};

export type GlobalNullableVideoAgentState = {
    session: null | VideoAgentSession;
    assetMap: null | VideoAgentAssetMap;
    config: VideoAgentConfig;
    isShowingCommandsView: boolean;
};

export type GlobalVideoAgentState = {
    session: VideoAgentSession;
    assetMap: VideoAgentAssetMap;
    config: VideoAgentConfig;
    isShowingCommandsView: boolean;
};

/** NOTE - these are actually pointing to non-CPU-intensive videos by default */
export const DEFAULT_ASSET_MAP = {
    listeningSrc: 'https://media.spinach.io/passive-1-1.mp4',
    speakingSrc: 'https://media.spinach.io/active-1-1.mp4',
    askSpinachThinking: 'https://media.spinach.io/ask-spinach-thinking.mp4',
    askSpinachAnswering: 'https://media.spinach.io/ask-spinach-answering.mp4',
};

const validateAndParseRegexesForConfig = (value: string[]): RegExp[] => {
    const validRegexes: RegExp[] = [];
    const invalidRegexes: string[] = [];

    value.forEach((str: string) => {
        try {
            const matches = str.match(/(\/?)(.+)\1([a-z]*)/i);
            if (matches) {
                const [, , body, flags] = matches;
                validRegexes.push(new RegExp(body, flags));
            } else {
                throw new Error('Invalid regex ' + str);
            }
        } catch (error) {
            invalidRegexes.push(str);
        }
    });

    if (invalidRegexes.length > 0) {
        Sentry.captureException(new Error('Invalid regexes in config'), {
            extra: { invalidRegexes: invalidRegexes.join(', ') },
        });
    }

    return validRegexes;
};

function regexpToString(regexp: RegExp): string {
    return `/${regexp.source}/${regexp.flags}`;
}

const startAgendaDefault = [
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}start_{4,}$/i,
    // /_{15,}start_{1,8}agenda_{12,}$/i,
    // /_{1,}start_{1,8}agenda_{22,}$/i,
    ///_{1,}(?:hey{1,8})?(?:spinach|spanish)_{1,20}start_{1,8}agenda_{1,}$/i,
    // /_{15,}let's_{1,8}go_{12,}$/i,
    // /_{1,}let's_{1,8}go_{22,}$/i,
    // /_{1,}(?:hey{1,8})?spinach_{1,20}let's_{1,8}go_{1,}$/i,
    // /_{15,}let's_{1,8}get_{1,8}started_{12,}$/i,
    // /_{1,}let's_{1,8}get_{1,8}started_{22,}$/i,
    // /_{1,}(?:hey{1,8})?spinach_{1,20}let's_{1,8}get_{1,8}started_{1,}$/i,
    // /_{15,}start_{1,8}the_{1,8}meeting_{12,}$/i,
    // /_{1,}start_{1,8}the_{1,8}meeting_{22,}$/i,
    // /_{1,}(?:hey{1,8})?spinach_{1,20}start_{1,8}the_{1,8}meeting_{1,}$/i,
];
const nextTopicDefault = [
    // /_{7,}next_{1,8}topic_{12,}$/i,
    // /_{1,}next_{1,8}topic_{22,}$/i,
    // /_{1,}(?:hey_{1,8})?(?:spinach|spanish)_{1,20}next_{1,8}topic_{1,}$/i,
    // /_{15,}next_{1,8}up{12,}$/i,
    // /_{1,}next_{1,8}up_{22,}$/i,
    // /_{1,}(?:hey_{1,8})?spinach_{1,20}next_{1,8}up_{1,}$/i,
    // /_{15,}next_{1,8}item_{12,}$/i,
    // /_{1,}next_{1,8}item_{22,}$/i,
    // /_{1,}(?:hey_{1,8})?spinach_{1,20}next_{1,8}item_{1,}$/i,
    // /_{15,}next_{1,8}person_{12,}$/i,
    // /_{1,}next_{1,8}person_{22,}$/i,
    // /_{1,}(?:hey{1,8})?(?:spinach|spanish)_{1,20}next_{1,8}person_{1,}$/i,
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,15}next_{5,}$/i,
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it|spring|string|sprints)_{1,1}(?:next|text|decks)_{18,}$/i, // less common longer silence requirement at the end
];
// const iAmDoneDefault = [
//     /_{15,}i'm_{1,8}done_{12,}$/i,
//     /_{1,}i'm_{1,8}done_{22,}$/i,
//     /_{15,}i_{1,8}_{1,8}done_{12,}$/i,
//     /_{1,}i_{1,8}_{1,8}done_{22,}$/i,
// ];

const previousTopicDefault = [
    // /_{15,}previous_{1,8}topic_{12,}$/i,
    // /_{1,}previous_{1,8}topic_{22,}$/i,
    // /_{1,}(?:hey_{1,8})?(?:spinach|spanish)_{1,20}previous_{1,8}topic_{1,}$/i,
    // /_{15,}previous_{1,8}item_{12,}$/i,
    // /_{1,}previous_{1,8}item_{22,}$/i,
    // /_{1,}(?:hey{1,8})?spinach_{1,20}previous_{1,8}item_{1,}$/i,
    // /_{15,}previous_{1,8}person_{12,}$/i,
    // /_{1,}previous_{1,8}person_{22,}$/i,
    // /_{1,}(?:hey{1,8})?(?:spinach|spanish)_{1,20}previous_{1,8}person_{1,}$/i,
    // /_{15,}go_{1,8}back_{12,}$/i,
    // /_{1,}go_{1,8}back_{22,}$/i,
    // /_{1,}(?:hey{1,8})?spinach_{1,20}go_{1,8}back_{1,}$/i,
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(?:back|pack|rack|sack)_{4,}$/i,
];

const questionExtractionRegex = [/(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)\s+(.*?)$/];

const questionMatcher = [
    // what's
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(?:whats|what's)_{1,20}(?:\w+_{0,10}){3,5}$/i,
    // yes/no
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(is|are|was|were|will|would|can|could|should|might|must|has|have|had|does|do|did|may|shall)_{1,20}(i|you|he|she|it|we|they|this|that|these)(?:_{1,20}\w+){0,3}$/i,
    // Named subjecd yes/no
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(is|are|was|were|will|would|can|could|should|might|must|has|have|had|does|do|did|may|shall)(?:_{1,20}\w+){2,5}$/i,
    // who, what, when, where, why, how
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(?:who|whom|whose|what|which|when|where|why|shall|how|how_{1,10}many|how_{1,10}much|how_{1,10}long|how_{1,10}often|how_{1,10}far|how_{1,10}old|how_{1,10}come)_{1,10}(is|are|was|were|will|would|can|could|should|might|must|has|have|had|does|do|did|didn't|doesn't|haven't|hasn't|won't|can't|couldn't|wouldn't|shouldn't)(?:_{1,20}\w+){0,3}$/i,
    // wondring question
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(?:i|we|they|he|she)_{1,10}(?:wonder|ask|inquire|question|ponder)_{1,5}(?:\w+_{0,10})_{0,10}(?:what|who|whom|whose|which|when|where|why|how)(?:_{1,20}\w+){0,3}$/i,
    // referencing questinon
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(?:to|for|with|by|about|in|on|at|from)_{1,5}(?:whom|what|which|when|where|why|how)(?:_{1,20}\w+){0,3}$/i,
];

const wakeWordDetected = [/_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_(?:_{1,20}\w+){0,3}\w$/i];

const introDefault = [/_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}intro_{4,}$/i];

const addTopicToAgenda = [
    // https://regex101.com/r/hSNEiR/1
    // /_{15,}(?:add|end|and|ed|aid|at|ad|adds)_{1,8}([a-zA-Z0-9'-]+(:?_{1,8}[a-zA-Z0-9'-]+){0,4})_{1,8}to_{1,8}the_{1,8}agenda_{12,}$/i,
    // /_{1,}(?:add|end|and|ed|aid|at|ad|adds)_{1,8}([a-zA-Z0-9'-]+(:?_{1,8}[a-zA-Z0-9'-]+){0,4})_{1,8}to_{1,8}the_{1,8}agenda_{27,}$/i,
    // /_{1,}(?:hey{1,8})?spinach_{1,20}(?:add|end|and|ed|aid|at|ad|adds)_{1,20}([a-zA-Z0-9'-]+(:?_{1,8}[a-zA-Z0-9'-]+){0,4})_{1,8}to_{1,8}the_{1,8}agenda_{1,}$/i,
    /_{1,}(?:spinach|spanish|finished|finish|bid_it|spin|spin_it)_{1,20}(?:add|end|and|ed|aid|at|ad|adds)_{1,20}([a-zA-Z0-9'-]+(:?_{1,15}[a-zA-Z0-9'-]+){0,4})_{22,}$/i,
];

const voiceCommands = ['Spinach Add <topic>', 'Spinach Start', 'Spinach Next', 'Spinach Back'];
const chatCommands = ['add <topic>', 'start', 'next', 'back', 'jump #', 'remove #', 'agenda'];

export const RemoteVideoAgentConfigSchema = z.object({
    meetingEndReminderInMinutes: z
        .number()
        .optional()
        .transform((o) => o ?? 5),
    longTopicReminderThresholdInMinutes: z
        .number()
        .optional()
        .transform((o) => o ?? 5),
    bannerDurationInMs: z
        .number()
        .optional()
        .transform((o) => o ?? 10000),
    chimeSoundUrl: z
        .string()
        .optional()
        .transform((o) => o ?? 'https://media.spinach.io/chime.mp3'),
    maxTopicsListedPerPage: z
        .number()
        .optional()
        .transform((o) => o ?? 5),
    storeRawTranscript: z
        .boolean()
        .optional()
        .transform((o) => o ?? true),
    startAgendaMatchersRegex: z
        .array(z.string())
        .optional()
        .default(startAgendaDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    nextTopicMatchersRegex: z
        .array(z.string())
        .optional()
        .default(nextTopicDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    previousTopicRegex: z
        .array(z.string())
        .optional()
        .default(previousTopicDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    introCommandRegex: z
        .array(z.string())
        .optional()
        .default(introDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    addTopicToTheAgendaMatchersRegex: z
        .array(z.string())
        .optional()
        .default(addTopicToAgenda.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    questionTriggerRegex: z
        .array(z.string())
        .optional()
        .default(questionMatcher.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    questionExtractionRegex: z
        .array(z.string())
        .optional()
        .default(questionExtractionRegex.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    wakeWordDetectedRegex: z
        .array(z.string())
        .optional()
        .default(wakeWordDetected.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    voiceCommandInstructions: z
        .array(z.string())
        .optional()
        .transform((o) => o ?? voiceCommands),
    chatCommandInstructions: z
        .array(z.string())
        .optional()
        .transform((o) => o ?? chatCommands),
    spinachIntro: z
        .object({
            loopVideo: z.string().default('https://media.spinach.io/intro-loop-1-1.mp4'),
            introVideo: z.string().default('https://media.spinach.io/intro-agent-1-1.mp4'),
        })
        .default({}),
    videos: z.record(z.string()).default({}),
});

export type VideoAgentConfig = z.infer<typeof RemoteVideoAgentConfigSchema>;

export const atomVideoAgent = atom<GlobalNullableVideoAgentState>({
    key: 'atomVideoAgent',
    default: {
        session: null,
        assetMap: null,
        config: RemoteVideoAgentConfigSchema.parse({}),
        // This may no longer be needed - left in case we bring it back
        isShowingCommandsView: false,
    },
});
