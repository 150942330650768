import { Box, Checkbox, TextField } from '@material-ui/core';
import lottie from 'lottie-web';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MEETING_LANGAUGES, MEETING_LANGUAGE_SEPARATOR } from '@spinach-shared/constants';
import { CalendarEvent, ClientEventType, FeatureToggle } from '@spinach-shared/types';

import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useLandingAnalytic,
    useLanguageSelectionInDashboardEnablement,
    useProAiPlanFeatureList,
    useWindowSize,
    withContentMasking,
} from '../../../../..';
import celebrateLottieJSON from '../../../../assets/celebrate.json';
import confettiGif from '../../../../assets/confetti-meme 1.gif';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/green-circle-check.svg';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import { BodyBigOnboard, BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Anchor, Column, DropDown, Row, SlidingColumn, Spacing } from '../../../common';
import { useSpinachInputStyles } from '../../../input';
import { ScrollView } from '../../../series';
import { PrimaryButton } from '../../../stand-up';
import { StepProps } from '../common';

export type ExperimentOneAboutFinishProps = StepProps & {
    firstName: string;
    setFirstName: (firstName: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    howDidYouHear: string;
    setHowDidYouHear: (howDidYouHear: string) => void;
    howDidYouHearOther: string;
    setHowDidYouHearOther: (str: string) => void;
    defaultMeetingLanguage?: string;
    setDefaultMeetingLanguage: (str: string) => void;
    onboardingEventsToAddScribeTo: CalendarEvent[];
};

export const ValidationLabel = styled.span`
    color: ${(props) => props.theme.secondary.midnight};
    font-size: 12px;
    min-height: 12px;
    width: 100%;
    text-align: left;
`;

const HowDidYouHearList = [
    {
        label: 'Someone recommended',
        code: 'someone-recommended',
    },
    {
        label: 'Saw it on a meeting',
        code: 'saw-it-on-a-meeting',
    },
    {
        label: 'Google search',
        code: 'google-search',
    },
    {
        label: 'LinkedIn',
        code: 'linkedin',
    },
    {
        label: 'Superhuman Newsletter',
        code: 'superhuman',
    },
    {
        label: 'G2',
        code: 'G2',
    },
    {
        label: 'TikTok',
        code: 'tiktok',
    },
    {
        label: 'Instagram',
        code: 'instagram',
    },
    {
        label: 'YouTube',
        code: 'youtube',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

const HeaderThree = styled(HeaderThreeOnboard)`
    position: relative;
    .celebrate-finish-animation {
        position: absolute;
        height: 400px !important;
        top: -200%;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: none;
    }
`;

export function ExperimentOneAboutFinishStep({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    direction,
    loadingMessage,
    onSubmit,
    howDidYouHear,
    setHowDidYouHear,
    howDidYouHearOther,
    setHowDidYouHearOther,
    defaultMeetingLanguage,
    setDefaultMeetingLanguage,
    onboardingEventsToAddScribeTo,
}: ExperimentOneAboutFinishProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAboutStep);
    const track = useExperienceTracking();
    const classes = useSpinachInputStyles({ value: '' });
    const { width, isDesktopView } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
    const onboardingPrivacyPolicyQuestion = user.featureToggles[FeatureToggle.OnboardingPrivacyPolicyCheckbox];
    const isDisabled =
        (onboardingPrivacyPolicyQuestion ? !acceptedPrivacyPolicy : false) ||
        !howDidYouHear ||
        !firstName.trim() ||
        !lastName.trim() ||
        !defaultMeetingLanguage;
    const languageSelectionEnabled = useLanguageSelectionInDashboardEnablement();
    const languages = MEETING_LANGAUGES.map((lang) => ({
        label: lang,
        code: lang,
        disabled: MEETING_LANGUAGE_SEPARATOR === lang,
    }));

    useEffect(() => {
        const container = document.querySelector('#finish-onb-header');
        if (!!container) {
            const animation = lottie.loadAnimation({
                container,
                autoplay: false,
                loop: false,
                animationData: celebrateLottieJSON,
                rendererSettings: {
                    className: 'celebrate-finish-animation',
                },
            });
            animation.setSpeed(0.33);
            animation.play();
        }
    }, []);

    const dropDownButtonProps = {
        style: {
            marginTop: '5px',
            width: width < 400 ? '250px' : '330px',
            transform: width < 400 ? 'scale(0.8)' : 'unset',
            marginLeft: width < 400 ? '-25px' : 'unset',
        },
    };
    const featurePlanListForNonPaying = useProAiPlanFeatureList();
    let featureList = user.isPayingUser
        ? ((user.featureToggles[FeatureToggle.ProAiPlanFeatureList].valueOf() as any)?.features as string[])
        : featurePlanListForNonPaying;
    featureList = featureList instanceof Array ? featureList : [];
    const trialDuration = user.featureToggles[FeatureToggle.ReverseTrialLengthInDays] as number;
    const midTrial = user.reverseTrialDaysLeft !== user.featureToggles[FeatureToggle.ReverseTrialLengthInDays];
    const trialIsOver = !user.reverseTrialDaysLeft;
    const isPayingAccount = user.isPayingUser;

    let spinachProText = `Spinach Pro features are enabled for the next ${user.reverseTrialDaysLeft} days!`;
    if (isPayingAccount) {
        spinachProText = `We hope you enjoy these features on Spinach Pro!`;
    } else if (midTrial || user.isOnManuallyManagedTrial) {
        spinachProText = `Spinach Pro was enabled for your company ${
            trialDuration - user.reverseTrialDaysLeft
        } days ago. You have ${user.reverseTrialDaysLeft} days left of these features`;
    } else if (trialIsOver) {
        if (user.isBusiness) {
            spinachProText = `Your company's Spinach Pro trial has ended. Upgrade to get these`;
        } else {
            spinachProText = `Your Spinach Pro trial has ended. Upgrade to get these`;
        }
    }

    return (
        <Row style={{ height: '100%' }}>
            <Column vCentered={true} centered={true} style={{ flex: 1, margin: '20px', width: 'calc(100% - 40px)' }}>
                <Row>
                    <SpinachLogo
                        style={{
                            height: isDesktopView ? '55px' : '25px',
                        }}
                    />
                </Row>
                <Column style={{ maxWidth: '600px', height: 'calc(100% - 55px)' }}>
                    <ScrollView sidePadding={0}>
                        <SlidingColumn centered direction={direction} style={{ padding: '10px' }}>
                            <HeaderThree id="finish-onb-header">All set! We'll see you here!</HeaderThree>

                            {onboardingEventsToAddScribeTo.length ? (
                                <>
                                    <Spacing factor={1 / 2} />

                                    <Column style={{ width: '90%' }}>
                                        {onboardingEventsToAddScribeTo.map((event, i) => (
                                            <Row
                                                key={event.id || i}
                                                style={{
                                                    border: `1px solid ${lightTheme.primary.orangeLight}`,
                                                    borderRadius: '5px',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                }}
                                                centered={true}
                                            >
                                                <BodyBigOnboard
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    <b>{event.summary}</b>
                                                </BodyBigOnboard>
                                            </Row>
                                        ))}
                                    </Column>
                                </>
                            ) : null}

                            <Spacing factor={1 / 2} />

                            <Row>
                                <Column>
                                    <div style={{ display: 'flex' }}>
                                        <Column style={{ width: '45%' }}>
                                            <BodyBigOnboard>First name</BodyBigOnboard>
                                            <Spacing factor={1 / 3} />

                                            <TextField
                                                value={firstName}
                                                InputProps={{ classes: { root: classes.base } }}
                                                {...withContentMasking(classes.root)}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                style={{ marginRight: '10px' }}
                                                inputProps={{ maxLength: 50 }}
                                                autoFocus
                                                placeholder="First name"
                                            />
                                        </Column>
                                        <Column style={{ width: '45%' }}>
                                            <BodyBigOnboard>Last name</BodyBigOnboard>
                                            <Spacing factor={1 / 3} />
                                            <TextField
                                                value={lastName}
                                                InputProps={{ classes: { root: classes.base } }}
                                                {...withContentMasking(classes.root)}
                                                onChange={(e) => setLastName(e.target.value)}
                                                inputProps={{ maxLength: 50 }}
                                                placeholder="Last name"
                                            />
                                        </Column>
                                    </div>
                                </Column>
                            </Row>

                            <Spacing />

                            {languageSelectionEnabled ? (
                                <>
                                    <Row>
                                        <BodyBigOnboard style={{ fontWeight: 500 }}>
                                            What language do you use in your meetings?
                                        </BodyBigOnboard>
                                    </Row>

                                    <Row>
                                        <DropDown
                                            buttonProps={dropDownButtonProps}
                                            title={'Select an option'}
                                            values={languages}
                                            handleSelection={(value) => {
                                                const meta = { DefaultMeetingLanguage: value };
                                                track(ClientEventType.OnboardingClick, {
                                                    ClickedOn: 'Default Meeting Language',
                                                    ...meta,
                                                });
                                                track(
                                                    ClientEventType.UserSelectedDefaultMeetingLanguageInOnboarding,
                                                    meta
                                                );
                                                setDefaultMeetingLanguage(value);
                                            }}
                                            selected={
                                                languages.find((t) => t.code === defaultMeetingLanguage)?.label ||
                                                undefined
                                            }
                                        />
                                    </Row>
                                    <Spacing />
                                </>
                            ) : null}

                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>How did you hear about us?</BodyBigOnboard>
                            </Row>

                            <Row>
                                <DropDown
                                    buttonProps={dropDownButtonProps}
                                    title={'Select an option'}
                                    values={HowDidYouHearList}
                                    handleSelection={(value: any) => {
                                        const meta = { Selection: value };
                                        track(ClientEventType.OnboardingClick, {
                                            ClickedOn: 'How Did You Hear Option',
                                            ...meta,
                                        });
                                        track(ClientEventType.UserSelectedHowDidYouHear, meta);
                                        setHowDidYouHear(value);
                                    }}
                                    selected={
                                        HowDidYouHearList.find((t) => t.code === howDidYouHear)?.label || undefined
                                    }
                                />
                            </Row>

                            {howDidYouHear === 'other' ? (
                                <>
                                    <Spacing factor={1 / 2} />
                                    <Row>
                                        <TextField
                                            placeholder="How did you hear?"
                                            value={howDidYouHearOther}
                                            onChange={(e) => {
                                                setHowDidYouHearOther(e.target.value);
                                            }}
                                            style={{ width: '88%', maxWidth: '356px' }}
                                            InputProps={{ classes: { root: classes.base } }}
                                            inputProps={{ maxLength: 50 }}
                                            {...withContentMasking(classes.root)}
                                        />
                                    </Row>
                                </>
                            ) : null}

                            <Spacing />

                            {onboardingPrivacyPolicyQuestion ? (
                                <Box display="flex" flexDirection="row" alignSelf="start">
                                    <Box>
                                        <Checkbox
                                            style={{ width: '20px', padding: 0 }}
                                            checked={acceptedPrivacyPolicy}
                                            onChange={() => {
                                                const newState = !acceptedPrivacyPolicy;
                                                const meta = {
                                                    state: newState ? 'checked' : 'unchecked',
                                                };
                                                track(ClientEventType.OnboardingClick, {
                                                    ClickedOn: 'Privacy Policy Checkbox',
                                                    ...meta,
                                                });
                                                track(ClientEventType.UserPrivacyPolicyCheckboxAction, meta);
                                                setAcceptedPrivacyPolicy(newState);
                                            }}
                                        />
                                    </Box>

                                    <BodyRegularOnboard style={{ fontWeight: 500, marginLeft: '10px' }}>
                                        {'I agree to the Spinach '}
                                        <Anchor
                                            style={{
                                                color: lightTheme.primary.greenLight,
                                                textDecoration: 'none',
                                                fontWeight: 'normal',
                                            }}
                                            onClick={() => {
                                                URLUtil.openURL('https://www.spinach.io/terms-of-service');
                                            }}
                                        >
                                            {'Terms of Service'}
                                        </Anchor>
                                        {' and '}
                                        <Anchor
                                            style={{
                                                color: lightTheme.primary.greenLight,
                                                textDecoration: 'none',
                                                fontWeight: 'normal',
                                            }}
                                            onClick={() => {
                                                URLUtil.openURL('https://www.spinach.io/privacy');
                                            }}
                                        >
                                            {'Privacy Policy'}
                                        </Anchor>
                                    </BodyRegularOnboard>
                                </Box>
                            ) : null}
                        </SlidingColumn>
                    </ScrollView>

                    <Spacing />
                    <PrimaryButton
                        onClick={onSubmit}
                        disabled={!!loadingMessage || isDisabled}
                        isLoading={!!loadingMessage}
                        title={'Go to Dashboard'}
                    />
                </Column>
            </Column>
            {width > 900 ? (
                <Column style={{ backgroundColor: '#F4F6F6', flex: 1 }} centered={true} vCentered={true}>
                    <Column
                        style={{
                            justifyContent: 'start',
                            borderRadius: '25px',
                            maxWidth: '60%',
                            minWidth: '300px',
                            maxHeight: '60%',
                            // minHeight: '500px',
                            border: `1px solid ${lightTheme.primary.orangeLight}`,
                            padding: '30px',
                        }}
                    >
                        <img src={confettiGif} style={{ width: '100%', marginBottom: '20px' }} />
                        <BodyBigOnboard style={{ maxWidth: '400px', textAlign: 'center' }}>
                            <b>{spinachProText}</b>
                        </BodyBigOnboard>
                        <Column style={{ marginTop: '15px', maxWidth: '350px', marginLeft: '20px' }}>
                            {featureList.map((feature) => (
                                <Row key={feature} style={{ marginBottom: '15px' }}>
                                    <GreenCheckIcon style={{ width: '18px', height: '18px', marginRight: '16px' }} />
                                    <BodyRegularOnboard style={{ fontSize: '18px' }}>{feature}</BodyRegularOnboard>
                                </Row>
                            ))}
                        </Column>
                    </Column>
                </Column>
            ) : null}
        </Row>
    );
}
