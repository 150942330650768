import { SpinachApp } from '@spinach-clients/shared';

import './App.css';

/**
 * Used to initialize any web-specific dependencies that may arise in the future
 */
function SpinachWebApp() {
    return <SpinachApp />;
}

export default SpinachWebApp;
