import { Box, Checkbox, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MEETING_LANGAUGES, MEETING_LANGUAGE_SEPARATOR } from '@spinach-shared/constants';
import { ClientEventType, FeatureToggle } from '@spinach-shared/types';

import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useLandingAnalytic,
    useLanguageSelectionInDashboardEnablement,
    useWindowSize,
} from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard, lightTheme } from '../../../styles';
import { URLUtil, withContentMasking } from '../../../utils';
import { Anchor, Column, DropDown, Row, SlidingColumn, Spacing } from '../../common';
import { useSpinachInputStyles } from '../../input';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up/PrimaryButton';
import { BackButtonRow, Stationary, StepProps } from './common';

export type AboutProps = StepProps & {
    firstName: string;
    setFirstName: (firstName: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    teamKind: string;
    setTeamKind: (teamKind: string) => void;
    role: string;
    setRole: (role: string) => void;
    otherRole: string;
    setOtherRole: (otherRole: string) => void;
    howDidYouHear: string;
    setHowDidYouHear: (howDidYouHear: string) => void;
    teamKindOther: string;
    setTeamKindOther: (str: string) => void;
    howDidYouHearOther: string;
    setHowDidYouHearOther: (str: string) => void;
    defaultMeetingLanguage?: string;
    setDefaultMeetingLanguage: (str: string) => void;
    onBack?: () => void;
};

export const ValidationLabel = styled.span`
    color: ${(props) => props.theme.secondary.midnight};
    font-size: 12px;
    min-height: 12px;
    width: 100%;
    text-align: left;
`;

const TeamKindList = [
    {
        label: 'Product/Engineering/Design',
        code: 'product-engineering-design',
    },
    {
        label: 'Operations/Project Management',
        code: 'operations-project-management',
    },
    {
        label: 'IT',
        code: 'it',
    },
    {
        label: 'Customer Support',
        code: 'customer-support',
    },
    {
        label: 'Sales/Marketing',
        code: 'sales/marketing',
    },
    {
        label: 'Finance/Legal/HR',
        code: 'finance-legal-hr',
    },
    {
        label: 'Management',
        code: 'management',
    },
];

const TeamRoleList = [
    {
        label: 'Individual Contributor',
        code: 'ic',
    },
    {
        label: 'Team Leader',
        code: 'team-leader',
    },
    {
        label: 'Director',
        code: 'director',
    },
    {
        label: 'Executive',
        code: 'executive',
    },
];

const HowDidYouHearList = [
    {
        label: 'Someone recommended',
        code: 'someone-recommended',
    },
    {
        label: 'Saw it on a meeting',
        code: 'saw-it-on-a-meeting',
    },
    {
        label: 'Google search',
        code: 'google-search',
    },
    {
        label: 'LinkedIn',
        code: 'linkedin',
    },
    {
        label: 'Superhuman Newsletter',
        code: 'superhuman',
    },
    {
        label: 'G2',
        code: 'G2',
    },
    {
        label: 'TikTok',
        code: 'tiktok',
    },
    {
        label: 'Instagram',
        code: 'instagram',
    },
    {
        label: 'YouTube',
        code: 'youtube',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

export function AboutStep({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    direction,
    loadingMessage,
    onSubmit,
    teamKind,
    setTeamKind,
    role,
    setRole,
    otherRole,
    setOtherRole,
    howDidYouHear,
    setHowDidYouHear,
    teamKindOther,
    setTeamKindOther,
    howDidYouHearOther,
    setHowDidYouHearOther,
    defaultMeetingLanguage,
    setDefaultMeetingLanguage,
    onBack,
}: AboutProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAboutStep);
    const track = useExperienceTracking();
    const classes = useSpinachInputStyles({ value: '' });
    const { width } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
    const disableRoleAndTypeQuestion = user.featureToggles[FeatureToggle.OnboardingDisableRoleAndTeamTypeQuestion];
    const onboardingPrivacyPolicyQuestion = user.featureToggles[FeatureToggle.OnboardingPrivacyPolicyCheckbox];
    const isDisabled =
        (disableRoleAndTypeQuestion ? false : !teamKind || !role) ||
        (onboardingPrivacyPolicyQuestion ? !acceptedPrivacyPolicy : false) ||
        !howDidYouHear ||
        !firstName.trim() ||
        !lastName.trim() ||
        !defaultMeetingLanguage;
    const languageSelectionEnabled = useLanguageSelectionInDashboardEnablement();
    const languages = MEETING_LANGAUGES.map((lang) => ({
        label: lang,
        code: lang,
        disabled: MEETING_LANGUAGE_SEPARATOR === lang,
    }));

    const dropDownButtonProps = {
        style: {
            marginTop: '5px',
            width: width < 400 ? '250px' : '330px',
            transform: width < 400 ? 'scale(0.8)' : 'unset',
            marginLeft: width < 400 ? '-25px' : 'unset',
        },
    };
    return (
        <Stationary style={{ minHeight: '490px' }}>
            {onBack ? <BackButtonRow onBack={onBack} /> : null}
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <HeaderThreeOnboard>Tell us about yourself</HeaderThreeOnboard>

                    <Spacing factor={1 / 2} />

                    <Row>
                        <Column>
                            <BodyBigOnboard>Your name</BodyBigOnboard>
                            <div style={{ display: 'flex-inline' }}>
                                <TextField
                                    value={firstName}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    style={{ marginRight: '10px' }}
                                    inputProps={{ maxLength: 50 }}
                                    autoFocus
                                    placeholder="First name"
                                />
                                <TextField
                                    value={lastName}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    onChange={(e) => setLastName(e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    placeholder="Last name"
                                />
                            </div>
                        </Column>
                    </Row>

                    <Spacing />

                    {disableRoleAndTypeQuestion ? (
                        <></>
                    ) : (
                        <>
                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>What team are you on?</BodyBigOnboard>
                            </Row>
                            <Row>
                                <DropDown
                                    buttonProps={dropDownButtonProps}
                                    title={'Select an option'}
                                    values={TeamKindList}
                                    handleSelection={(value) => {
                                        setTeamKind(value);
                                        track(ClientEventType.UserSelectedTeamKind, {
                                            TeamKind: value,
                                        });
                                    }}
                                    selected={TeamKindList.find((t) => t.code === teamKind)?.label || undefined}
                                />
                            </Row>

                            {teamKind === 'other' ? (
                                <>
                                    <Spacing factor={1 / 2} />
                                    <Row>
                                        <TextField
                                            placeholder="What kind of team is it?"
                                            value={teamKindOther}
                                            onChange={(e) => {
                                                setTeamKindOther(e.target.value);
                                            }}
                                            style={{ width: '88%', maxWidth: '356px' }}
                                            InputProps={{ classes: { root: classes.base } }}
                                            inputProps={{ maxLength: 50 }}
                                            {...withContentMasking(classes.root)}
                                        />
                                    </Row>
                                </>
                            ) : null}

                            <Spacing />
                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>What role are you in?</BodyBigOnboard>
                            </Row>
                            <Row>
                                <DropDown
                                    title={'What role are you in?'}
                                    buttonProps={dropDownButtonProps}
                                    values={TeamRoleList}
                                    handleSelection={(value) => {
                                        setRole(value);
                                        track(ClientEventType.UserSelectedTeamRole, {
                                            Role: value,
                                        });
                                    }}
                                    selected={TeamRoleList.find((t) => t.code === role)?.label || undefined}
                                />
                            </Row>

                            {role === 'other' ? (
                                <>
                                    <Spacing factor={1 / 2} />
                                    <Row>
                                        <TextField
                                            placeholder="Your role"
                                            value={otherRole}
                                            onChange={(e) => {
                                                setOtherRole(e.target.value);
                                            }}
                                            style={{ width: '88%', maxWidth: '356px' }}
                                            InputProps={{ classes: { root: classes.base } }}
                                            inputProps={{ maxLength: 50 }}
                                            {...withContentMasking(classes.root)}
                                        />
                                    </Row>
                                </>
                            ) : null}

                            <Spacing />
                        </>
                    )}

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>How did you hear about us?</BodyBigOnboard>
                    </Row>

                    <Row>
                        <DropDown
                            buttonProps={dropDownButtonProps}
                            title={'Select an option'}
                            values={HowDidYouHearList}
                            handleSelection={(value) => {
                                track(ClientEventType.UserSelectedHowDidYouHear);
                                setHowDidYouHear(value);
                            }}
                            selected={HowDidYouHearList.find((t) => t.code === howDidYouHear)?.label || undefined}
                        />
                    </Row>

                    {howDidYouHear === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="How did you hear?"
                                    value={howDidYouHearOther}
                                    onChange={(e) => {
                                        setHowDidYouHearOther(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    {...withContentMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />
                    {languageSelectionEnabled ? (
                        <>
                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>
                                    What language are the majority of your meetings in?
                                </BodyBigOnboard>
                            </Row>

                            <Row>
                                <DropDown
                                    buttonProps={dropDownButtonProps}
                                    title={'Select an option'}
                                    values={languages}
                                    handleSelection={(value) => {
                                        track(ClientEventType.UserSelectedDefaultMeetingLanguageInOnboarding, {
                                            DefaultMeetingLanguage: value,
                                        });
                                        setDefaultMeetingLanguage(value);
                                    }}
                                    selected={
                                        languages.find((t) => t.code === defaultMeetingLanguage)?.label || undefined
                                    }
                                />
                            </Row>
                            <Spacing />
                        </>
                    ) : null}

                    {onboardingPrivacyPolicyQuestion ? (
                        <Box display="flex" flexDirection="row" alignSelf="start">
                            <Box>
                                <Checkbox
                                    style={{ width: '20px', padding: 0 }}
                                    checked={acceptedPrivacyPolicy}
                                    onChange={() => {
                                        const newState = !acceptedPrivacyPolicy;
                                        track(ClientEventType.UserPrivacyPolicyCheckboxAction, {
                                            state: newState ? 'checked' : 'unchecked',
                                        });
                                        setAcceptedPrivacyPolicy(newState);
                                    }}
                                />
                            </Box>

                            <BodyBigOnboard style={{ fontWeight: 500, marginLeft: '10px' }}>
                                {'I agree to the Spinach '}
                                <Anchor
                                    style={{
                                        color: lightTheme.primary.greenLight,
                                        textDecoration: 'none',
                                        fontWeight: 'normal',
                                    }}
                                    onClick={() => {
                                        URLUtil.openURL('https://www.spinach.io/terms-of-service');
                                    }}
                                >
                                    {'Terms of Service'}
                                </Anchor>
                                {' and '}
                                <Anchor
                                    style={{
                                        color: lightTheme.primary.greenLight,
                                        textDecoration: 'none',
                                        fontWeight: 'normal',
                                    }}
                                    onClick={() => {
                                        URLUtil.openURL('https://www.spinach.io/privacy');
                                    }}
                                >
                                    {'Privacy Policy'}
                                </Anchor>
                            </BodyBigOnboard>
                        </Box>
                    ) : null}
                </SlidingColumn>
            </ScrollView>

            <Spacing />
            <PrimaryButton
                onClick={onSubmit}
                disabled={!!loadingMessage || isDisabled}
                isLoading={!!loadingMessage}
                title={'Next'}
            />
        </Stationary>
    );
}
