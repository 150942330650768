import { ClientEventType, SummaryJson, UserPlansAndProgress } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../..';
import { BodyRegularOnboard, HeaderThree } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SectionComponentProps, SummaryTextField } from '../shared';

export function PersonalUpdatesSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Updates</HeaderThree>
            </Row>
            {summaryJSON.plansAndProgress?.map((pp, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Personal Update',
                            });
                            const updatedItems = [...(summaryJSON.plansAndProgress ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                plansAndProgress: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={pp.userName}
                                onChange={(e) => {
                                    const updatedPlansAndProgress = [...(summaryJSON.plansAndProgress ?? [])];
                                    updatedPlansAndProgress[i] = {
                                        ...updatedPlansAndProgress[i],
                                        userName: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        plansAndProgress: updatedPlansAndProgress,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Whose update?"
                            />
                            <Row
                                style={{
                                    width: 'calc(100% - 20px)',
                                    marginLeft: '20px',
                                }}
                            >
                                <Column>
                                    <BodyRegularOnboard>
                                        <b>Plans:</b>
                                    </BodyRegularOnboard>

                                    <SummaryTextField
                                        value={pp.plans}
                                        onChange={(e) => {
                                            const updatedPlansAndProgress = [...(summaryJSON.plansAndProgress ?? [])];
                                            updatedPlansAndProgress[i] = {
                                                ...updatedPlansAndProgress[i],
                                                plans: e.target.value,
                                            };
                                            const updatedJson: SummaryJson = {
                                                ...summaryJSON,
                                                plansAndProgress: updatedPlansAndProgress,
                                            };
                                            setSummaryJSON(updatedJson);
                                        }}
                                        placeholder="Their plans"
                                    />

                                    <BodyRegularOnboard>
                                        <b>Progress:</b>
                                    </BodyRegularOnboard>

                                    <SummaryTextField
                                        value={pp.progress}
                                        onChange={(e) => {
                                            const updatedPlansAndProgress = [...(summaryJSON.plansAndProgress ?? [])];
                                            updatedPlansAndProgress[i] = {
                                                ...updatedPlansAndProgress[i],
                                                progress: e.target.value,
                                            };
                                            const updatedJson: SummaryJson = {
                                                ...summaryJSON,
                                                plansAndProgress: updatedPlansAndProgress,
                                            };
                                            setSummaryJSON(updatedJson);
                                        }}
                                        placeholder="Their progress"
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Personal Update',
                    });
                    const blankItem: UserPlansAndProgress = {
                        email: '',
                        id: '',
                        plans: '',
                        progress: '',
                        userName: '',
                    };
                    const updatedItems = [...(summaryJSON.plansAndProgress ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        plansAndProgress: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add an Update"
            />
        </>
    );
}
