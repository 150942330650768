import { ClientEventType, MeetingAgendaItem } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../hooks';
import { HeaderThree } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SummaryTextField } from '../shared';
import { SectionComponentProps } from '../shared';

export function AgendaItemSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();
    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Key Topics</HeaderThree>
            </Row>

            {summaryJSON.agendaItems?.map((item, i) => {
                return (
                    <ItemRow
                        key={i}
                        rowIndex={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Key Topic',
                            });
                            const updatedAgendaItems = [...(summaryJSON.agendaItems ?? [])];
                            updatedAgendaItems.splice(i, 1);

                            const updatedJson = {
                                ...summaryJSON,
                                agendaItems: updatedAgendaItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                    >
                        <Column>
                            <SummaryTextField
                                placeholder="Key Topic Title"
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={item.content}
                                onChange={(e) => {
                                    const updatedAgendaItems = [...(summaryJSON.agendaItems ?? [])];
                                    updatedAgendaItems[i] = {
                                        ...updatedAgendaItems[i],
                                        content: e.target.value,
                                    };
                                    const updatedJson = {
                                        ...summaryJSON,
                                        agendaItems: updatedAgendaItems,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                            />
                            <SummaryTextField
                                placeholder="Key Topic Context"
                                value={item.context}
                                onChange={(e) => {
                                    const updatedAgendaItems = [...(summaryJSON.agendaItems ?? [])];
                                    updatedAgendaItems[i] = {
                                        ...updatedAgendaItems[i],
                                        context: e.target.value,
                                    };
                                    const updatedJson = {
                                        ...summaryJSON,
                                        agendaItems: updatedAgendaItems,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                            />
                        </Column>
                    </ItemRow>
                );
            })}
            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Key Topic',
                    });
                    const blankAgendaItem: MeetingAgendaItem = {
                        context: '',
                        content: '',
                    };
                    const updatedAgendaItems = [...(summaryJSON.agendaItems ?? []), blankAgendaItem];
                    const updatedJson = {
                        ...summaryJSON,
                        agendaItems: updatedAgendaItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Key Topic"
            />
        </>
    );
}
