import { SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postToggleVideoAgentForSeries(
    seriesId: string,
    toggleTo: boolean
): Promise<StoredSeries | undefined> {
    const response = await postSpinachAPI<{ storedSeries: StoredSeries }>(SpinachAPIPath.VideoAgentForSeries, {
        seriesId,
        toggleTo,
    });
    return response?.storedSeries;
}
