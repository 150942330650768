import { ClientEventType, MeetingBlocker, SummaryJson } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../..';
import { HeaderThree } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SectionComponentProps, SummaryTextField } from '../shared';

export function BlockerSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Blockers</HeaderThree>
            </Row>

            {summaryJSON.blockers?.map((blocker, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Blocker',
                            });
                            const updatedItems = [...(summaryJSON.blockers ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                blockers: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={blocker.user}
                                onChange={(e) => {
                                    const updatedBlockers = [...(summaryJSON.blockers ?? [])];
                                    updatedBlockers[i] = {
                                        ...updatedBlockers[i],
                                        user: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        blockers: updatedBlockers,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Owners"
                            />
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={blocker.content}
                                onChange={(e) => {
                                    const updatedBlockers = [...(summaryJSON.blockers ?? [])];
                                    updatedBlockers[i] = {
                                        ...updatedBlockers[i],
                                        content: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        blockers: updatedBlockers,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Blocker title"
                            />
                            <SummaryTextField
                                value={blocker.context}
                                onChange={(e) => {
                                    const updatedBlockers = [...(summaryJSON.blockers ?? [])];
                                    updatedBlockers[i] = {
                                        ...updatedBlockers[i],
                                        context: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        blockers: updatedBlockers,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Blocker context"
                            />
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Blocker',
                    });
                    const blankItem: MeetingBlocker = {
                        content: '',
                        context: '',
                        user: '',
                    };
                    const updatedItems = [...(summaryJSON.blockers ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        blockers: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Blocker"
            />
        </>
    );
}
