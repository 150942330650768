import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ClientEventType, MeetingSettingsSubview } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { GlobalModal } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useWindowSize,
} from '../../../../hooks';
import { useGlobalMeetingSettings } from '../../../../hooks/useGlobalMeetingSettings';
import { BodyBigOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { withContentMasking } from '../../../../utils';
import { Column, Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useOpenInMeetingApp } from '../meetings/CombinedMeetingDetails';

export function StandupDetails({
    isOpen,
    setIsOpen,
    storedSeries,
}: {
    isOpen: boolean;
    storedSeries: StoredSpinachSeries;
    setIsOpen: () => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();

    const track = useExperienceTracking();

    const [, setGlobalStoredSeries] = useGlobalNullableStoredSeries();
    const [, setGlobalModal] = useGlobalModal();
    const { setSubview } = useGlobalMeetingSettings();

    const { width } = useWindowSize();
    const isDesktopView = width > 800;

    const openInMeetingApp = useOpenInMeetingApp();

    return (
        <Row
            style={{
                marginBottom: '20px',
                marginLeft: '10px',
            }}
        >
            <Accordion
                expanded={isOpen}
                onChange={setIsOpen}
                elevation={4}
                style={{ width: isDesktopView ? '80%' : '95%' }}
            >
                <AccordionSummary>
                    <Row
                        style={{
                            alignItems: width > 500 ? 'center' : 'start',
                            padding: '5px',
                            height: width > 500 ? '20px' : 'unset',
                        }}
                    >
                        <ExpandMore
                            style={{ transition: '100ms', transform: !isOpen ? 'rotate(270deg)' : 'rotate(360deg)' }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: width > 500 ? 'row' : 'column',
                                justifyContent: width > 500 ? 'space-between' : 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <BodyBigOnboard
                                style={{
                                    marginRight: '15px',
                                    marginLeft: '15px',
                                    textAlign: 'left',
                                    marginTop: width > 500 ? '0px' : '2px',
                                    width: '100%',
                                }}
                                {...withContentMasking()}
                            >
                                <b {...withContentMasking()}>{storedSeries.name}</b>
                            </BodyBigOnboard>
                            <span style={{ display: 'flex', flexGrow: 1 }} />
                            <PrimaryButton
                                size={ButtonSize.Small}
                                containerStyles={width <= 500 ? { width: '100%', marginTop: '10px' } : undefined}
                                title="Launch"
                                onClick={(e) => {
                                    e?.stopPropagation();
                                    openInMeetingApp(storedSeries, 'Series Row');
                                }}
                            />
                        </div>
                    </Row>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px', paddingTop: '0px' }}>
                    <Column style={{ width: '100%' }}>
                        <Row>
                            <span style={{ width: isDesktopView ? '40px' : '0px' }} />
                            <SecondaryButton
                                size={isDesktopView ? ButtonSize.Normal : ButtonSize.Mini}
                                buttonStyles={{ textDecoration: 'none' }}
                                color={lightTheme.primary.greenLight}
                                title={`Manage Additional Recipients (${storedSeries.dailySubscribedEmails.length})`}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Manage additional recipients',
                                    });
                                    setGlobalStoredSeries(storedSeries);
                                    setGlobalModal(GlobalModal.SubscribeSeriesOutput);
                                }}
                            />
                        </Row>

                        <Spacing factor={1 / 2} />

                        <Row>
                            <span style={{ width: isDesktopView ? '40px' : '0px' }} />
                            <SecondaryButton
                                size={isDesktopView ? ButtonSize.Normal : ButtonSize.Mini}
                                title={`Invite Teammates`}
                                color={lightTheme.primary.greenLight}
                                buttonStyles={{ textDecoration: 'none' }}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Invite Teammates',
                                    });
                                    setGlobalStoredSeries(storedSeries);
                                    setGlobalModal(GlobalModal.ShareSeries);
                                }}
                            />
                        </Row>

                        <Spacing factor={1 / 2} />

                        <Row>
                            <span style={{ width: isDesktopView ? '40px' : '0px' }} />
                            <SecondaryButton
                                size={isDesktopView ? ButtonSize.Normal : ButtonSize.Mini}
                                title={`Slack Channel Selection`}
                                buttonStyles={{ textDecoration: 'none' }}
                                color={lightTheme.primary.greenLight}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Slack Channel Selection',
                                    });
                                    setGlobalStoredSeries(storedSeries);
                                    setGlobalModal(GlobalModal.IntegrationsSettings);
                                    if (user.isAuthedForSlack) {
                                        // the integration settings modal must load and hydrate state
                                        // before the subview can be set otherwise the slack channel
                                        // wont be pre-populated as it should be
                                        setTimeout(() => {
                                            setSubview(MeetingSettingsSubview.SlackSettings);
                                        }, 300);
                                    }
                                }}
                            />
                        </Row>

                        <Spacing factor={1 / 2} />

                        <Row>
                            <span style={{ width: isDesktopView ? '40px' : '0px' }} />
                            <SecondaryButton
                                size={isDesktopView ? ButtonSize.Normal : ButtonSize.Mini}
                                title={`Rename Standup`}
                                buttonStyles={{ textDecoration: 'none' }}
                                color={lightTheme.primary.greenLight}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Rename Standup',
                                    });
                                    setGlobalStoredSeries(storedSeries);
                                    setGlobalModal(GlobalModal.RenameSeries);
                                }}
                            />
                        </Row>

                        <Spacing factor={1 / 2} />

                        <Row>
                            <span style={{ width: isDesktopView ? '40px' : '0px' }} />
                            <SecondaryButton
                                size={isDesktopView ? ButtonSize.Normal : ButtonSize.Mini}
                                title={`Leave Standup`}
                                buttonStyles={{ textDecoration: 'none' }}
                                color={lightTheme.primary.greenLight}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Leave Standup',
                                    });
                                    setGlobalStoredSeries(storedSeries);
                                    setGlobalModal(GlobalModal.RemoveSeries);
                                }}
                            />
                        </Row>
                    </Column>
                </AccordionDetails>
            </Accordion>
        </Row>
    );
}
