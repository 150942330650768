import { Box, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { ScrollArea, Spacing, useExperienceTracking, useGlobalAiDashboard, withContentMasking } from '../../../../..';
import { useGlobalDrafts } from '../../../../hooks/useGlobalDrafts';
import { lightTheme } from '../../../../styles';
import { Row } from '../../../common';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { MeetingSelection } from '../types';
import { DraftEditor } from './editor/DraftEditor';

export function EmptyState() {
    const track = useExperienceTracking();
    const { setActiveSection } = useGlobalAiDashboard();

    return (
        <>
            <Spacing factor={1} />
            <div>
                You don&apos;t have any meeting summaries in drafts.
                <br />
                Meetings with{' '}
                <div style={{ display: 'inline-block' }}>
                    <SecondaryButton
                        title="Edit Summary"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Edit Summary in drafts section',
                            });
                            setActiveSection(DashboardSection.Integrations);
                        }}
                    />
                </div>{' '}
                enabled will appear here before they are sent.
            </div>
        </>
    );
}

const SELECTION_CODE_DELIMITER = '::';

function getHistorySelectionCode(seriesId: string, botId: string): string {
    return `${seriesId}${SELECTION_CODE_DELIMITER}${botId}`;
}

export function DraftsSection() {
    const track = useExperienceTracking();
    const [options, setOptions] = useState<MeetingSelection[]>([]);
    const [selection, setSelection] = useState<MeetingSelection | null>(null);

    const [searchParams] = useSearchParams();
    const selectedBotId = searchParams.get(WebUrlQuery.BotId);
    const [drafts, isFetching, refetch] = useGlobalDrafts();

    useEffect(() => {
        async function setUpOptions() {
            const mapped: MeetingSelection[] = (drafts ?? []).map((h) => ({
                botId: h.botId,
                seriesId: h.seriesId,
                code: getHistorySelectionCode(h.seriesId, h.botId),
                label: `${h.meetingTitle} (${moment(h.createdAt).format('YYYY/MM/DD')})`,
                verbalDate: moment(h.createdAt).format('dddd, MMM Do YYYY'),
                title: h.meetingTitle,
                createdAt: new Date(h.createdAt),
                isPartialCappedSummary: h.isPartialCappedSummary,
                icpId: h.icpId,
                ticket: h.ticket,
            }));
            setOptions(mapped);

            const selected = !!selectedBotId ? mapped.find((option) => option.botId === selectedBotId) : mapped[0];

            if (selected) {
                setSelection(selected);
            }
        }

        setUpOptions();
    }, [drafts, selectedBotId]);

    return (
        <Box height="94%" display="flex" flexDirection="column" marginY="32px" marginX="0" overflow="hidden">
            <Spacing factor={1 / 2} />
            <Row vCenter style={{ paddingLeft: '32px' }}>
                <Autocomplete
                    id="meeting-selection"
                    options={options}
                    getOptionLabel={(option: MeetingSelection) => {
                        return option.label;
                    }}
                    value={selection}
                    size="small"
                    style={{
                        minWidth: '60%',
                        marginBottom: '10px',
                        width: '100%',
                        maxWidth: '600px',
                    }}
                    autoComplete
                    onChange={async (event, selection) => {
                        setSelection(selection);

                        if (!selection) {
                            setSelection(null);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Cleared drafts Selection',
                                NumberOfDrafts: drafts?.length,
                            });
                            return;
                        }

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Selected Draft to Open',
                            NumberOfDrafts: drafts?.length,
                        });
                    }}
                    {...withContentMasking()}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Choose a draft" placeholder="Choose a draft" />
                    )}
                />
                {isFetching ? (
                    <CircularProgress
                        size={'30px'}
                        style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                    />
                ) : (
                    <span style={{ width: '40px' }} />
                )}
            </Row>

            {selection ? (
                <ScrollArea style={{ height: '95%', padding: '0' }}>
                    <DraftEditor
                        botId={selection.botId}
                        onErrorLoadingSummary={() => {
                            setSelection(null);
                        }}
                        onDraftSent={async () => {
                            setSelection(null);
                            await refetch();
                        }}
                    />
                </ScrollArea>
            ) : null}
            {!isFetching && !options.length ? <EmptyState /> : null}
        </Box>
    );
}
