import { ClientEventType, MeetingGeneratedItem, SummaryJson } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../..';
import { HeaderThree } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SummaryTextField } from '../shared';

export function TextSectionEditor({
    sectionTitle,
    text,
    onUpdatedText,
}: {
    sectionTitle: string;
    text?: string;
    onUpdatedText: (text: string) => void;
}) {
    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>{sectionTitle}</HeaderThree>
            </Row>
            <Column>
                <SummaryTextField
                    placeholder={sectionTitle}
                    value={text}
                    onChange={(e) => {
                        onUpdatedText(e.target.value);
                    }}
                />
            </Column>
        </>
    );
}
export function MeetingGeneratedItemsSectionEditor({
    sectionTitle,
    items,
    itemTypeName,
    onUpdatedItems,
}: {
    sectionTitle: string;
    itemTypeName: string;
    items?: MeetingGeneratedItem[];
    onUpdatedItems: (items: MeetingGeneratedItem[]) => void;
}) {
    const track = useExperienceTracking();
    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>{sectionTitle}</HeaderThree>
            </Row>

            {items?.map((item, i) => {
                return (
                    <ItemRow
                        key={i}
                        rowIndex={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: itemTypeName,
                            });
                            const updatedItems = [...(items ?? [])];
                            updatedItems.splice(i, 1);

                            onUpdatedItems(updatedItems);
                        }}
                    >
                        <Column>
                            <SummaryTextField
                                placeholder={`${itemTypeName} Title`}
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={item.title}
                                onChange={(e) => {
                                    const updatedItems = [...(items ?? [])];
                                    updatedItems[i] = {
                                        ...updatedItems[i],
                                        title: e.target.value,
                                    };
                                    onUpdatedItems(updatedItems);
                                }}
                            />
                            <SummaryTextField
                                placeholder={`${itemTypeName} Context`}
                                value={item.context}
                                onChange={(e) => {
                                    const updatedItems = [...(items ?? [])];
                                    updatedItems[i] = {
                                        ...updatedItems[i],
                                        context: e.target.value,
                                    };
                                    onUpdatedItems(updatedItems);
                                }}
                            />
                        </Column>
                    </ItemRow>
                );
            })}
            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: itemTypeName,
                    });
                    const blankItem: MeetingGeneratedItem = {
                        context: '',
                        title: '',
                    };
                    const updatedItems = [...(items ?? []), blankItem];
                    onUpdatedItems(updatedItems);
                }}
                buttonText="Add an item"
            />
        </>
    );
}

export type MeetingGeneratedItemsConfiguration<T> = {
    title: string;
    itemType: string;
    key: keyof T;
    kind: 'generated-items';
};

export type MeetingTextConfiguration<T> = {
    title: string;
    key: keyof T;
    kind: 'text';
};

export type SectionConfiguration<T> = MeetingGeneratedItemsConfiguration<T> | MeetingTextConfiguration<T>;

export type GenericSectionEditorProps<T, K extends keyof SummaryJson> = {
    summaryJSON: SummaryJson;
    setSummaryJSON: (newSummary: SummaryJson) => void;
    sections: SectionConfiguration<T>[];
    sectionKey: K;
};

export function GenericSectionEditor<T, K extends keyof SummaryJson>({
    summaryJSON,
    setSummaryJSON,
    sections,
    sectionKey,
}: GenericSectionEditorProps<T, K>): JSX.Element {
    // Use a type assertion to tell TypeScript that summaryJSON[sectionKey] is of type T
    const section = summaryJSON[sectionKey] as unknown as T;

    if (!section) {
        return <></>;
    }

    const updateTextSection = (key: keyof T, text: string) => {
        setSummaryJSON({
            ...summaryJSON,
            [sectionKey]: {
                ...section,
                [key]: text,
            },
        });
    };

    const updateGeneratedItemsSection = (key: keyof T, items: MeetingGeneratedItem[]) => {
        setSummaryJSON({
            ...summaryJSON,
            [sectionKey]: {
                ...section,
                [key]: items || [],
            },
        });
    };

    return (
        <>
            {sections.map((config) => {
                if (config.kind === 'text') {
                    return (
                        <TextSectionEditor
                            key={config.key as string}
                            sectionTitle={config.title}
                            text={section[config.key] as unknown as string}
                            onUpdatedText={(text) => updateTextSection(config.key, text)}
                        />
                    );
                } else if (config.kind === 'generated-items') {
                    return (
                        <MeetingGeneratedItemsSectionEditor
                            key={config.key as string}
                            sectionTitle={config.title}
                            itemTypeName={config.itemType}
                            items={section[config.key] as unknown as MeetingGeneratedItem[]}
                            onUpdatedItems={(items) => updateGeneratedItemsSection(config.key, items)}
                        />
                    );
                }
            })}
        </>
    );
}
