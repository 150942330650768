import { MeetingCustomerSuccessCheckInJSON } from '@spinach-shared/types';

import { SectionComponentProps } from '../shared';
import { GenericSectionEditor, SectionConfiguration } from './GenericSectionEditor';

export function CustomerSuccessCheckInSectionEditor({
    summaryJSON,
    setSummaryJSON,
}: SectionComponentProps): JSX.Element {
    const sections: SectionConfiguration<MeetingCustomerSuccessCheckInJSON>[] = [
        { title: 'Call Summary', kind: 'text', key: 'callSummary' },
        { title: 'Highlights', itemType: 'Highlight', key: 'highlights', kind: 'generated-items' },
        {
            title: 'Concerns & Challenges',
            itemType: 'Concern or Challenge',
            key: 'concernsChallenges',
            kind: 'generated-items',
        },
        { title: 'Requests', itemType: 'Request', key: 'requests', kind: 'generated-items' },
        { title: 'Best Practices', itemType: 'Best Practice', key: 'bestPractices', kind: 'generated-items' },
        { title: 'Key Takeaways', itemType: 'Key Takeaway', key: 'keyTakeaways', kind: 'generated-items' },
    ];

    return (
        <GenericSectionEditor<MeetingCustomerSuccessCheckInJSON, 'customerSuccessCheckInSummary'>
            summaryJSON={summaryJSON}
            setSummaryJSON={setSummaryJSON}
            sections={sections}
            sectionKey="customerSuccessCheckInSummary"
        />
    );
}
